import {
	Button,
	Container,
	Dialog,
	DialogActions,
	IconButton,
	ImageList,
	ImageListItem,
	Stack,
	Typography,
	AppBar,
	Toolbar,
	Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import PrintIcon from "@mui/icons-material/Print";
import React, { useEffect, useState, useContext, useRef } from "react";
import axios from "axios";
import { getLookupData, getOrderDataByID, imageURL } from "../../constant/url";
import { AccessContext } from "../../constant/accessContext";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import html2canvas from 'html2canvas';
// import html2pdf from 'html2pdf.js';

// import { usePDF } from 'react-to-pdf';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PreviewIcon from "@mui/icons-material/Preview";
import Slide from "@mui/material/Slide";
import "../../Table.css";
import logo from "../../assets/img/sc-straight.jpeg";
import AutoCompleteOrder from "../Component/AutoCompleteOrder";
// import { jsPDF } from "jspdf";
// import { useReactToPrint } from 'react-to-print';
// import { useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrderViewModal(prop) {
	let orderRowID = prop.orderId;

	// const access = useSelector((state) => state.auth?.accessCode);
	const access = useContext(AccessContext).authID;

	let dateObj = new Date();
	const [orderNo, setOrderNo] = useState();
	const [splitId, setSplitId] = useState();
	const [orderDate, setOrderDate] = useState(
		dateObj.getDate() + "/" + dateObj.getMonth() + "/" + dateObj.getFullYear()
	);
	const [customerName, setCustomerName] = useState([]);
	const [length, setLength] = useState(null);
	const [height, setHeight] = useState(null);
	const [row, setRow] = useState(null);
	const [quantity, setQuantity] = useState(null);
	const [sqfeet, setSqFeet] = useState(0);
	const [size, setSize] = useState(0);
	const [pipeType, setPipeType] = useState("");
	const [expansionType, setExpansionType] = useState("");
	//New Pipe Bend components
	const [pbStraight, setPBStraight] = useState(false);
	const [pbStraightQty, setPBStraightQty] = useState(0);
	const [pbStraightSize, setPBStraightSize] = useState(0);
	const [pbStraightTotQty, setPBStraightTotQty] = useState(0);

	const [pbSingle, setPBSingle] = useState(false);
	const [pbSingleQty, setPBSingleQty] = useState(0);
	const [pbSingleSize, setPBSingleSize] = useState(0);
	const [pbSingleTotQty, setPBSingleTotQty] = useState(0);

	const [pbCross, setPBCross] = useState(false);
	const [pbCrossQty, setPBCrossQty] = useState(0);
	const [pbCrossSize, setPBCrossSize] = useState(0);
	const [pbCrossTotQty, setPBCrossTotQty] = useState(0);

	const [pbOther, setPBOther] = useState(false);
	const [pbOtherQty, setPBOtherQty] = useState(0);
	const [pbOtherSize, setPBOtherSize] = useState(0);
	const [pbOtherTotQty, setPBOtherTotQty] = useState(0);

	const [pipeComment, setPipeComment] = useState("");
	const [endPlateMaterial, setEndPlateMaterial] = useState("");
	const [endPlateModel, setEndPlateModel] = useState("");
	const [endPlateOrientation, setEndPlateOrientation] = useState([]);
	const [coverType, setCoverType] = useState([]);
	const [coverDetail, setCoverDetail] = useState([]);
	const [epComments, setEPComments] = useState("");
	const [finPerInch, setFinPerInch] = useState("");
	const [finComments, setFinComments] = useState("");
	const [circuitModels, setCircuitModels] = useState([]);
	const [noCircuit, setNoCircuit] = useState("");
	const [liquidLine, setLiquidLine] = useState("");
	const [dischargeLine, setDischargeLine] = useState("");
	const [brazingComments, setBrazingComments] = useState("");
	const [paintType, setPaintType] = useState([]);
	const [packingType, setPackingType] = useState([]);
	const [dispatchMode, setDispatchMode] = useState([]);
	const [dispatchComment, setDispatchComments] = useState("");
	const [finalComments, setFinalComments] = useState("");
	const [finalConcatComments, setFinalConcatComments] = useState("");
	const [epPhoto, setEpPhoto] = useState([]);
    
	const[epDate,setEndPlateMaterials] = useState([]);


	const [assemblyPhoto, setAssemblyPhoto] = useState([]);
	const [brazingPhoto, setBrazingPhoto] = useState([]);
	const [lookUpList, setLookupList] = React.useState([]);
	/**
	 * Coil Detail States
	 */
	const [cncNestingStatus, setCncNestingStatus] = useState([]);
	const [cncNestingDate, setCncNestingDate] = useState([]);
	const [cncNestingStatusDate, setCncNestingStatusDate] = useState([]);
	const [cncNestingPgm, setCncNestingPgm] = useState([]);
	const [cncPunchingStatus, setCncPunchingStatus] = useState([]);
	const [cncPunchingDate, setCncPunchingDate] = useState([]);
	const [bendingStatus, setBendingStatus] = useState([]);

	const [bendingDate, setBendingDate] = useState([]);

	const [tCuttingStatus, setTCuttingStatus] = useState([]);


	const [tCuttingRollNo, setTCuttingRollNo] = useState([]);
	const [tCuttingDate, setTCuttingDate] = useState([]);

	const [tCuttingDateTime, setTCuttingDateTime] = useState([]);

 


	const [finPunchStatus, setFinPunchStatus] = useState([]);
	const [finPunchDate, setFinPunchDate] = useState([]);
	const [finFoilNo, setFinFoilNo] = useState([]);
	const [brazingExpansion, setBrazingExpansion] = useState([]);
	const [brazingStatus, setBrazingStatus] = useState([]);
	const [brazingDate, setBrazingDate] = useState([]);
	const [caStatus, setCaStatus] = useState([]);
	const [caStatusDate, setCaStatusDate] = useState([]);
	const [ceStatus, setCeStatus] = useState([]);
	const [ceStatusDate, setCeStatusDate] = useState([]);
	const [ppStatus, setPpStatus] = useState([]);
	const [ppDate, setPPDate] = useState([]);
	const [ppStatusDate, setPpStatusDate] = useState([]);
	const [dispatchStatus, setDispatchStatus] = useState([]);
	const [dispatchDate, setDispatchDate] = useState([]);

	const [isUpdate, setIsUpdate] = useState(false);
	const [brazingDetails, setBrazingDetails] = useState([]);
	const [openImg, setOpenImg] = useState(false);
	const [dialogImg, setDialogImg] = useState("");
	const [brazingTestingImages, setBrazingTestingImages] = useState([]);

	const handleClickOpenimg = (base64) => {
		setDialogImg(base64);
		setOpenImg(true);
	};

	const handleCloseImg = (response) => {
		setOpenImg(false);
	};

	const handleSqFeet = () => {
		setSqFeet(((length * height * row * quantity) / 144).toFixed(2));
	};

	const handleSize = () => {
		const len = length === null ? 0 : length;
		const heig = height === null ? 0 : height;
		const rowval = row === null ? 0 : row;
		const qty = quantity === null ? 0 : quantity;

		let x =
			String(len) +
			" x " +
			String(heig) +
			" x " +
			String(rowval) +
			"R - " +
			String(qty);
		setSize(x);
	};

	function handleGetLookup() {
		var bodyFormData = new FormData();
		bodyFormData.append("authId", access);

		/**add photos */
		axios({
			method: "post",
			url: getLookupData,
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then(function (response) {
				//handle success
				const res_data = response.data;
				if (res_data.status_code === 200) {
					setLookupList(res_data);
				} else {
					//console.log(res_data.status_msg);
				}
			})
			.catch(function (response) {
				//handle error
				console.log(response);
			});
	}

	const handleOrderinfo1 = () => {
		var bodyFormData = new FormData();
		bodyFormData.append("authId", access);
		bodyFormData.append("id", orderRowID);

		axios({
			method: "post",
			url: getOrderDataByID,
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then(function (response) {
				const res_data = response.data;
				if (res_data.status_code === 101) {
					toast("Api Aithentication failed. login again.");
				} else if (res_data.status_code === 200) {
					const ret_data_cd = res_data.data_orders;

					setOrderNo(ret_data_cd[0].order_id);
					setSplitId(ret_data_cd[0].split_id);
					setOrderDate(ret_data_cd[0].order_date);
					setCustomerName(ret_data_cd[0].full_customer_name);
					setLength(ret_data_cd[0].length);
					setHeight(ret_data_cd[0].height);
					setRow(ret_data_cd[0].rows);
					setQuantity(ret_data_cd[0].quantity);
					setSqFeet(ret_data_cd[0].sq_feet);
					setPipeType(ret_data_cd[0].pipe_type);
					setExpansionType(ret_data_cd[0].expansion_type);

					setPBStraight(ret_data_cd[0].pbStraight);
					setPBStraightQty(ret_data_cd[0].pbStraightQty);
					setPBStraightSize(ret_data_cd[0].pbStraightSize);
					setPBStraightTotQty(ret_data_cd[0].pbStraightTotQty);

					setPBSingle(ret_data_cd[0].pbSingle);
					setPBSingleQty(ret_data_cd[0].pbSingleQty);
					setPBSingleSize(ret_data_cd[0].pbSingleSize);
					setPBSingleTotQty(ret_data_cd[0].pbSingleTotQty);

					setPBCross(ret_data_cd[0].pbCross);
					setPBCrossQty(ret_data_cd[0].pbCrossQty);
					setPBCrossSize(ret_data_cd[0].pbCrossSize);
					setPBCrossTotQty(ret_data_cd[0].pbCrossTotQty);

					setPBOther(ret_data_cd[0].pbOther);
					setPBOtherQty(ret_data_cd[0].pbOtherQty);
					setPBOtherSize(ret_data_cd[0].pbOtherSize);
					setPBOtherTotQty(ret_data_cd[0].pbOtherTotQty);

					setPipeComment(ret_data_cd[0].pipe_comment);
					setEndPlateMaterial(ret_data_cd[0].end_plate_material);
					setEndPlateModel(ret_data_cd[0].end_plate_modal);
					setEndPlateOrientation(ret_data_cd[0].end_plate_orientation);
					setCoverType(ret_data_cd[0].cover_type);
					setCoverDetail(ret_data_cd[0].cover_detail);
					setEPComments(ret_data_cd[0].ep_comments);
					setFinPerInch(ret_data_cd[0].fin_per_inch);
					setFinComments(ret_data_cd[0].fin_comments);
					setCircuitModels(ret_data_cd[0].circuit_models);
					setNoCircuit(ret_data_cd[0].circuit_no);
					setLiquidLine(ret_data_cd[0].liquid_line);
					setDischargeLine(ret_data_cd[0].discharge_line);
					setBrazingComments(ret_data_cd[0].brazing_comment);
					setPaintType(ret_data_cd[0].paint);
					setPackingType(ret_data_cd[0].packing_type);
					setDispatchMode(ret_data_cd[0].dispatch_mode);
					setDispatchComments(ret_data_cd[0].dispatch_comment);
					setFinalComments(ret_data_cd[0].final_comment);

					setEpPhoto(ret_data_cd[0].ep_photo);

					setEndPlateMaterials(ret_data_cd[0].ep_DateTime);

					setAssemblyPhoto(ret_data_cd[0].assembly_Photo);

					setBrazingPhoto(ret_data_cd[0].brazing_Photo);

					setCncNestingStatus(ret_data_cd[0].cnc_nesting_status);
					setCncNestingPgm(ret_data_cd[0].cnc_nesting_pgm_no);
					setCncNestingDate(ret_data_cd[0].cnc_nested);
					setCncNestingStatusDate(ret_data_cd[0].cnc_nesting_status_dt);
					setCncPunchingStatus(ret_data_cd[0].cnc_punching_status);
					setCncPunchingDate(ret_data_cd[0].cnc_punching_status_dt);
					setBendingStatus(ret_data_cd[0].bending_status);

					setBendingDate(ret_data_cd[0].bending_status_dt);

					setTCuttingRollNo(ret_data_cd[0].tcutting_roll_no);

					setTCuttingDate(ret_data_cd[0].tcutting_datetime);


					setTCuttingDateTime(ret_data_cd[0].tcutting_status_dt);


					setTCuttingStatus(ret_data_cd[0].tcutting_status);


					setFinPunchStatus(ret_data_cd[0].finpunch_status);
					setFinPunchDate(ret_data_cd[0].finpunch_status_dt);
					setFinFoilNo(ret_data_cd[0].finpunching_foilno);
					setBrazingStatus(ret_data_cd[0].brazing_status);
					setBrazingDate(ret_data_cd[0].brazing_status_dt);
					setBrazingExpansion(ret_data_cd[0].ce_status_dt);
					setCaStatus(ret_data_cd[0].ca_status);
					setCaStatusDate(ret_data_cd[0].ca_status_dt);
					setCeStatus(ret_data_cd[0].ce_status);
					setCeStatusDate(ret_data_cd[0].ce_status_dt);
					setPpStatus(ret_data_cd[0].pp_status);
					setPPDate(ret_data_cd[0].pp_datetime);
					setPpStatusDate(ret_data_cd[0].pp_status_dt);
					setDispatchStatus(ret_data_cd[0].dispatch_status);
					setDispatchDate(ret_data_cd[0].dispatch_status_dt);
					setBrazingDetails(ret_data_cd[0].brazing_details);
					setBrazingTestingImages(ret_data_cd[0].brazing_testing_Photo);

					return ret_data_cd;
				} else {
					//console.log(res_data.status_msg);
				}
			})
			.catch(function (response) {
				console.log(response);
			});
	};





	// function printPage() {
	//     window.print();
	// }






	//autotable
	// const generatePDF = (orderNo) => {
	// 	const doc = new jsPDF({
	// 	  orientation: 'portrait',
	// 	  unit: 'px',
	// 	  format: 'a4',
	// 	});

	// 	const element = document.getElementById('orderDetails');
	// 	if (!element) {
	// 	  console.error('Order details element not found');
	// 	  return;
	// 	}
	// 	const tempStyle = document.createElement('style');
	// 	tempStyle.innerHTML = `
	// 	  @media print {
	// 		#orderDetails {
	// 		  max-width: 100% !important;
	// 		  width: 100% !important;
	// 		}
	// 		#orderDetails img {
	// 		  max-width: 100px !important;
	// 		  max-height: 100px !important;
	// 		  width: 100px !important;
	// 		  height: 100px !important;
	// 		  object-fit: contain !important;
	// 		  display: inline-block !important;
	// 		}
	// 		#orderDetails table {
	// 		  width: 100% !important;
	// 		  page-break-inside: avoid !important;
	// 		}
	// 	  }
	// 	`;
	// 	document.head.appendChild(tempStyle);


	// 	const tables = element.querySelectorAll('table');


	// 	let yPosition = 10; 


	// 	tables.forEach((table, index) => {
	// 	  const tableHeight = table.offsetHeight;  

	// 	  if (yPosition + tableHeight > doc.internal.pageSize.height) {
	// 		doc.addPage(); 
	// 		yPosition = 10;  
	// 	  }
	// 	  const tableWidth = doc.internal.pageSize.width - 250;
	// 	  doc.autoTable({
	// 		html: table,  
	// 		startY: yPosition, 
	// 		theme: 'grid',  
	// 		margin: { top: 5, bottom: 10 },  
	// 		tableWidth: tableWidth,
	// 	  });

	// 	  yPosition = doc.lastAutoTable.finalY + 10; 
	// 	});

	// 	doc.save(`order_${orderNo}.pdf`);
	// 	document.head.removeChild(tempStyle);
	//   };







	// const generatePDF = async (orderNo) => {
	// 	const doc = new jsPDF({
	// 		orientation: "portrait",
	// 		unit: "px",
	// 		format: "a4",
	// 	});

	// 	const element = document.getElementById("orderDetails");
	// 	if (!element) {
	// 		console.error("Order details element not found");
	// 		return;
	// 	}

	// 	const tables = element.querySelectorAll("table");
	// 	const images = element.querySelectorAll("img.MuiImageListItem-img");
	// 	console.log(images)

	// 	let yPosition = 20;
	// 	const pageWidth = doc.internal.pageSize.width;
	// 	const pageHeight = doc.internal.pageSize.height;
	// 	const bottomMargin = 20; // Space at the bottom

	// 	const headers = [
	// 		"Order Details",
	// 		"End Plate",
	// 		"Pipe",
	// 		"Fins",
	// 		"Brazing",
	// 		"Paint,Packing and Dispatch",
	// 		"Coil Details",
	// 		"Brazing & Leak Testing Details",
	// 	];

	// 	const fullWidthTables = ["Order Details", "Coil Details"];
	// 	const forceNewPageTables = ["Coil Details", "Brazing & Leak Testing Details"];

	// 	for (let i = 0; i < tables.length; i++) {
	// 		const table = tables[i];
	// 		const tableHeader = headers[i] ? headers[i] : `Table ${i-7}`;

	// 		if (forceNewPageTables.includes(tableHeader)) {
	// 			doc.addPage();
	// 			yPosition = 20;
	// 		}

	// 		doc.setFont("helvetica", "bold");
	// 		doc.setFontSize(14);
	// 		doc.text(tableHeader, 20, yPosition);
	// 		yPosition += 15;
	// 		let tableWidth = fullWidthTables.includes(tableHeader) ? pageWidth - 40 : pageWidth * 0.6;
	// 		let imgWidth = fullWidthTables.includes(tableHeader) ? 0 : pageWidth * 0.3;
	// 		let imgHeight = 100;
	// 		let imgXPosition = tableWidth + 20;

	// 		let tempDoc = new jsPDF({ orientation: "portrait", unit: "px", format: "a4" });
	// 		tempDoc.autoTable({ html: table, startY: 0 });
	// 		let estimatedTableHeight = tempDoc.lastAutoTable.finalY;


	// 		if (yPosition + estimatedTableHeight > pageHeight - bottomMargin) {
	// 			doc.addPage();
	// 			yPosition = 20;
	// 			doc.text(tableHeader, 20, yPosition);
	// 			yPosition += 15;
	// 		}

	// 		doc.autoTable({
	// 			html: table,
	// 			startY: yPosition,
	// 			theme: "grid",
	// 			margin: { left: 20 },
	// 			tableWidth: tableWidth,
	// 		});

	// 		let tableBottomY = doc.lastAutoTable.finalY;

	// 		if (!fullWidthTables.includes(tableHeader) && images[i]) {
	// 			const imgSrc = images[i].src;

	// 			await new Promise((resolve) => {
	// 				const img = new Image();
	// 				img.crossOrigin = "anonymous";
	// 				img.src = imgSrc;
	// 				img.onload = () => {
	// 					const canvas = document.createElement("canvas");
	// 					canvas.width = img.width;
	// 					canvas.height = img.height;
	// 					const ctx = canvas.getContext("2d");
	// 					ctx.drawImage(img, 0, 0);
	// 					const base64Image = canvas.toDataURL("image/jpeg");

	// 					if (tableBottomY + imgHeight > pageHeight - bottomMargin) {
	// 						doc.addPage();
	// 						tableBottomY = 20;
	// 					}

	// 					doc.addImage(base64Image, "JPEG", imgXPosition, tableBottomY, imgWidth, imgHeight);
	// 					resolve();
	// 				};
	// 			});
	// 		}

	// 		yPosition = tableBottomY + imgHeight - 70;
	// 	}

	// 	doc.save(`order_${orderNo}.pdf`);
	// };



	//final
	// const generatePDF = async (orderNo) => {
	// 	const doc = new jsPDF({
	// 		orientation: "portrait",
	// 		unit: "px",
	// 		format: "a4",
	// 	});

	// 	const element = document.getElementById("orderDetails");
	// 	if (!element) {
	// 		console.error("Order details element not found");
	// 		return;
	// 	}

	// 	const tables = element.querySelectorAll("table");
	// 	const images = Array.from(element.querySelectorAll("img.MuiImageListItem-img"));
	// 	console.log("Total images found:", images.length);

	// 	let yPosition = 20;
	// 	const pageWidth = doc.internal.pageSize.width;
	// 	const pageHeight = doc.internal.pageSize.height;
	// 	const bottomMargin = 10;
	// 	const imageTableGap = 1;  
	// 	const headers = [
	// 		"Order Details",
	// 		"End Plate",
	// 		"Pipe",
	// 		"Fins",
	// 		"Brazing",
	// 		"Paint,Packing and Dispatch",
	// 		"Coil Details",
	// 		"Brazing & Leak Testing Details",
	// 	];

	// 	const fullWidthTables = ["Coil Details"];
	// 	const forceNewPageTables = ["Coil Details", "Brazing & Leak Testing Details"];

	// 	let imageIndex = 0;

	// 	for (let i = 0; i < tables.length; i++) {
	// 		const table = tables[i];
	// 		const tableHeader = headers[i] || `Table ${i - 7}`;


	// 		if (forceNewPageTables.includes(tableHeader)) {
	// 			doc.addPage();
	// 			yPosition = 30;
	// 		}

	// 		doc.setFont("helvetica", "bold");
	// 		doc.setFontSize(14);
	// 		doc.text(tableHeader, 10, yPosition);
	// 		yPosition += 10;

	// 		// Table & image layout
	// 		let tableWidth = fullWidthTables.includes(tableHeader) ? pageWidth-10 : pageWidth * 0.5;
	// 		let imgWidth = fullWidthTables.includes(tableHeader) ? 0 : pageWidth * 0.4;
	// 		let imgHeight = 90;
	// 		let imgXPosition = tableWidth;

	// 		let tempDoc = new jsPDF({ orientation: "portrait", unit: "px", format: "a4" });
	// 		tempDoc.autoTable({ html: table, startY: 0 });
	// 		let estimatedTableHeight = tempDoc.lastAutoTable.finalY;

	// 		if (yPosition + estimatedTableHeight > pageHeight - bottomMargin) {
	// 			doc.addPage();
	// 			yPosition = 20;
	// 			doc.text(tableHeader, 20, yPosition);
	// 			yPosition += 15;
	// 		}

	// 		doc.autoTable({
	// 			html: table,
	// 			startY: yPosition,
	// 			theme: "grid",
	// 			margin: { left: 5},
	// 			tableWidth: tableWidth,
	// 		});

	// 		let tableBottomY = doc.lastAutoTable.finalY;

	// 		if (tableHeader === "Order Details" || imageIndex < images.length) {


	// 			const imgSrc = images[imageIndex]?.src;
	// 			if (imgSrc) {
	// 				await new Promise((resolve) => {
	// 					const img = new Image();
	// 					img.crossOrigin = "anonymous";
	// 					img.src = imgSrc;

	// 					img.onload = () => {
	// 						const canvas = document.createElement("canvas");
	// 						canvas.width = img.width;
	// 						canvas.height = img.height;
	// 						const ctx = canvas.getContext("2d");
	// 						ctx.drawImage(img, 0, 0);
	// 						const base64Image = canvas.toDataURL("image/jpeg");

	// 						if (tableBottomY + imgHeight + imageTableGap > pageHeight - bottomMargin) {
	// 							doc.addPage();
	// 							tableBottomY = 20;
	// 						}

	// 						doc.addImage(base64Image, "JPEG", imgXPosition, tableBottomY + imageTableGap, imgWidth, imgHeight);
	// 						imageIndex++;
	// 						resolve();
	// 					};

	// 					img.onerror = () => {
	// 						console.error("Failed to load image:", imgSrc);
	// 						resolve();
	// 					};
	// 				});
	// 			}
	// 		}

	// 		yPosition = tableBottomY + imgHeight-70;
	// 	}

	// 	// Save the PDF
	// 	doc.save(`order_${orderNo}.pdf`);
	// };


	//updated
	// const generatePDF = async (orderNo) => {
	// 	const doc = new jsPDF({
	// 		orientation: "portrait",
	// 		unit: "px",
	// 		format: "a4",
	// 	});

	// 	const element = document.getElementById("orderDetails");
	// 	if (!element) {
	// 		console.error("Order details element not found");
	// 		return;
	// 	}

	// 	const tables = element.querySelectorAll("table ");
	// 	// const images = Array.from(element.querySelectorAll("img.MuiImageListItem-img"));
	// 	// console.log("Total images found:", images.length);

	// 	let yPosition = 20;
	// 	const pageWidth = doc.internal.pageSize.width;
	// 	const pageHeight = doc.internal.pageSize.height;
	// 	const bottomMargin = 50;
	// 	// const imageTableGap = 10;
	// 	// const leftMargin = 250;

	// 	const headers = [
	// 		"Order Details",
	// 		"End Plate",
	// 		"Pipe",
	// 		"Fins",
	// 		"Brazing",
	// 		"Paint,Packing and Dispatch",
	// 		"Coil Details",
	// 		"Brazing & Leak Testing Details",
	// 	];

	// 	const fullWidthTables = ["Coil Details","Order Details"];
	// 	const forceNewPageTables = ["Coil Details", "Brazing & Leak Testing Details"];

	// 	// let imageIndex = 0;


	// 	const globalHeadStyles = {
	// 		fontStyle: "bold",  
	// 		fontSize: 10,  
	// 		fillColor: [220, 220, 220],  
	// 		textColor: [0, 0, 0],   
	// 		halign: "center",   
	// 	};

	// 	for (let i = 0; i < tables.length; i++) {
	// 		const table = tables[i];
	// 		const tableHeader = headers[i] || `Table ${i - 7}`;

	// 		if (tableHeader === "Brazing & Leak Testing Details") {
	// 			doc.addPage();
	// 			yPosition = 30;
	// 		}

	// 		let tempDoc = new jsPDF({ orientation: "portrait", unit: "px", format: "a4" });
	// 		tempDoc.autoTable({ html: table, startY: 0 });
	// 		let estimatedTableHeight = tempDoc.lastAutoTable.finalY;

	// 		if (yPosition + estimatedTableHeight > pageHeight - bottomMargin) {
	// 			doc.addPage();
	// 			yPosition = 30;
	// 		}

	// 		doc.setFont("helvetica", "bold");
	// 		doc.setFontSize(14);
	// 		doc.text(tableHeader, 10, yPosition);
	// 		yPosition += 10;

	// 		let tableWidth = fullWidthTables.includes(tableHeader) ? pageWidth - 10 : pageWidth * 0.5;
	// 		// let imgWidth = fullWidthTables.includes(tableHeader) ? 0 : pageWidth * 0.4;
	// 		// let imgHeight = 90;
	// 		// let imgXPosition = leftMargin;


	// 		let tableStyles = {
	// 			styles: {
	// 				fontSize: 10,
	// 				lineWidth: 0.1,
	// 				lineColor: [0, 0, 0],
	// 			},
	// 		};

	// 		if (tableHeader !== "Order Details") {
	// 			tableStyles = {
	// 				styles: { fontSize: 10, lineColor: [0, 0, 0], lineWidth: 0.2 },
	// 				columnStyles: {
	// 					0: { cellWidth: 80 },
	// 					1: { cellWidth: 150 },
	// 				},
	// 			};
	// 		}

	// 		if (tableHeader === "Coil Details") {
	// 			tableStyles.columnStyles = {
	// 				0: { cellWidth: 100, halign: 'center' },
	// 				1: { cellWidth: 120, halign: 'center' },
	// 				2: { cellWidth: 200, halign: 'center' },
	// 			};
	// 		}

	// 		doc.autoTable({
	// 			html: table,
	// 			startY: yPosition,
	// 			theme: "grid",
	// 			margin: { left: 5 },
	// 			tableWidth: tableWidth,
	// 			headStyles: globalHeadStyles, 
	// 			...tableStyles,  
	// 		});

	// 		let tableBottomY = doc.lastAutoTable.finalY;


	// 		// if (imageIndex < images.length) {
	// 		// 	const imgSrc = images[imageIndex]?.src;
	// 		// 	if (imgSrc) {
	// 		// 		await new Promise((resolve) => {
	// 		// 			const img = new Image();
	// 		// 			img.crossOrigin = "anonymous";
	// 		// 			img.src = imgSrc;

	// 		// 			img.onload = () => {
	// 		// 				const canvas = document.createElement("canvas");
	// 		// 				canvas.width = img.width;
	// 		// 				canvas.height = img.height;
	// 		// 				const ctx = canvas.getContext("2d");
	// 		// 				ctx.drawImage(img, 0, 0);
	// 		// 				const base64Image = canvas.toDataURL("image/jpeg");

	// 		// 				// Check if the image fits, else move to the next page
	// 		// 				if (tableBottomY + imgHeight + imageTableGap > pageHeight - bottomMargin) {
	// 		// 					doc.addPage();
	// 		// 					tableBottomY = 20;
	// 		// 				}

	// 		// 				doc.addImage(base64Image, "JPEG", imgXPosition, tableBottomY + imageTableGap, imgWidth, imgHeight);
	// 		// 				imageIndex++;
	// 		// 				resolve();
	// 		// 			};

	// 		// 			img.onerror = () => {
	// 		// 				console.error("Failed to load image:", imgSrc);
	// 		// 				resolve();
	// 		// 			};
	// 		// 		});
	// 		// 	}
	// 		// }

	// 		yPosition = tableBottomY+20  ;  
	// 	}


	// 	doc.save(`order_${orderNo}.pdf`);
	// };





	const generatePDF = async (orderNo) => {
		const doc = new jsPDF({
			orientation: "portrait",
			unit: "mm",
			format: "a4",
		});

		try {
			const orderDetailsElement = document.getElementById("orderDetails");

			if (!orderDetailsElement) {
				console.error("Order details element not found");
				return;
			}


			const logoImg = new Image();
			logoImg.src = logo;
			await new Promise((resolve, reject) => {
				logoImg.onload = resolve;
				logoImg.onerror = (error) => {
					console.warn("Logo image failed to load:", error);
					resolve();
				};

				setTimeout(resolve, 3000);
			});

			let yPosition = 12;
			const margin = 10;
			const pageWidth = doc.internal.pageSize.getWidth();


			// const addImagesToPDF = async (doc, images, yPosition, margin) => {
			// 	const pageHeight = doc.internal.pageSize.getHeight();
			// 	const pageWidth = doc.internal.pageSize.getWidth();
			// 	const maxImageWidth = 65;
			// 	const maxImageHeight = 35;

			// 	let imageX = pageWidth - maxImageWidth - margin;

			// 	for (const imgPath of images) {
			// 		if (!imgPath) continue;

			// 		const img = new Image();
			// 		img.src = imageURL + imgPath;

			// 		await new Promise((resolve) => {
			// 			img.onload = resolve;
			// 			img.onerror = () => {
			// 				console.warn("Failed to load image:", imgPath);
			// 				resolve();
			// 			};
			// 		});

			// 		if (yPosition + maxImageHeight > pageHeight - 20) {
			// 			doc.addPage();
			// 			yPosition = 20;
			// 		}

			// 		doc.addImage(img, "JPEG", imageX, yPosition, maxImageWidth, maxImageHeight);
			// 		yPosition += maxImageHeight + 10;
			// 	}

			// 	return yPosition;
			// };



			const addImagesToPDF = async (doc, images, yPosition, margin) => {
				const pageHeight = doc.internal.pageSize.getHeight();
				const pageWidth = doc.internal.pageSize.getWidth();
				const maxImageWidth = 65;
				const maxImageHeight = 35;

				let imageX = pageWidth - maxImageWidth - margin;


				if (yPosition === 20) {

					yPosition = 50;
				}

				for (const imgPath of images) {
					if (!imgPath) continue;

					const img = new Image();
					img.src = imageURL + imgPath;

					await new Promise((resolve) => {
						img.onload = resolve;
						img.onerror = () => {
							console.warn("Failed to load image:", imgPath);
							resolve();
						};
					});


					if (yPosition + maxImageHeight > pageHeight - 20) {
						doc.addPage();
						yPosition = 20;
					}


					doc.addImage(img, "JPEG", imageX, yPosition, maxImageWidth, maxImageHeight);

					yPosition += maxImageHeight + 10;
				}

				return yPosition;
			};



			const addHeaderToPage = () => {

				if (logoImg.complete && logoImg.naturalWidth !== 0) {
					const logoWidth = 70; // Width of the logo as you're setting it
					const logoHeight = 20; // Height of the logo as you're setting it
					const logoX = (pageWidth - logoWidth) / 2; // 

					doc.addImage(logoImg, 'PNG', logoX, 10, logoWidth, logoHeight);
				}
				return 40;
			};

			yPosition = addHeaderToPage();


			doc.setFontSize(16);
			doc.setFont("helvetica", "bold");
			doc.text("Order Details", margin, yPosition);
			yPosition += 3;


			const orderData = {
				"Order No": orderNo + (splitId || " "),
				"Date": orderDate || " ",
				"Customer": customerName || " ",
				"Size": size || " ",
				"Sq Feet": sqfeet || " "
			};

			doc.autoTable({
				startY: yPosition,
				head: [Object.keys(orderData)],
				body: [Object.values(orderData)],
				theme: 'grid',
				margin: { left: margin, right: margin },
				headStyles: { fillColor: [255, 255, 255], fontStyle: 'bold', textColor: [0, 0, 0] },
				styles: { overflow: 'linebreak', cellPadding: 3, lineWidth: 0.3, lineColor: [0, 0, 0] }
			});

			yPosition = doc.lastAutoTable.finalY + 6;


			if (yPosition > doc.internal.pageSize.getHeight() - 20) {
				doc.addPage();
				yPosition = addHeaderToPage();
			}


			// const addSpecificationsSection = (title, data) => {
			// 	if (yPosition > doc.internal.pageSize.getHeight() - 50) {
			// 		doc.addPage();
			// 		yPosition = addHeaderToPage();
			// 	}

			// 	doc.setFontSize(14);
			// 	doc.setFont("helvetica", "bold");
			// 	doc.text(title, margin, yPosition);
			// 	yPosition += 2;


			// 	const body = Object.entries(data).map(([key, value]) => [key, value?.toString() || ""]);

			// 	doc.autoTable({
			// 		startY: yPosition,
			// 		body: body,
			// 		theme: 'grid',
			// 		margin: { left: margin, right: margin },
			// 		styles: { overflow: 'linebreak', cellPadding: 3, lineWidth: 0.3, lineColor: [0, 0, 0] },
			// 		columnStyles: { 0: { cellWidth: 40, fontStyle: 'bold', textColor: [0, 0, 0] }, },
			// 		tableWidth: 100,
			// 	});
			// 	yPosition = doc.lastAutoTable.finalY +6;
			// };



			//final
			// const addSpecificationsSection = async (title, data, images) => {
			// 	if (yPosition > doc.internal.pageSize.getHeight() - 50) {
			// 		doc.addPage();
			// 		yPosition = addHeaderToPage();
			// 	}
			// 	doc.setFontSize(14);
			// 	doc.setFont("helvetica", "bold");
			// 	doc.text(title, margin, yPosition);
			// 	yPosition += 3;
			// 	const tableWidth = pageWidth * 0.5;
			// 	const body = Object.entries(data).map(([key, value]) => [key, value?.toString() || ""]);
			// 	doc.autoTable({
			// 		startY: yPosition,
			// 		body: body,
			// 		theme: 'grid',
			// 		margin: { left: margin, right: margin + tableWidth },
			// 		styles: { overflow: 'linebreak', cellPadding: 3, lineWidth: 0.3, lineColor: [0, 0, 0] },
			// 		columnStyles: { 0: { cellWidth: 35, fontStyle: 'bold', textColor: [0, 0, 0] } },
			// 		tableWidth: tableWidth,
			// 	});
			// 	const tableEndY = doc.lastAutoTable.finalY;
			// 	if (images && images.length > 0) {
			// 		const imageX = margin + tableWidth + 10;
			// 		let imageY = yPosition;
			// 		const maxImageWidth = 65;
			// 		const maxImageHeight = 35;
			// 		for (const imgPath of images) {
			// 			if (!imgPath) continue;
			// 			const img = new Image();
			// 			img.src = imageURL + imgPath;
			// 			await new Promise((resolve) => {
			// 				img.onload = resolve;
			// 				img.onerror = () => {
			// 					console.warn("Failed to load image:", imgPath);
			// 					resolve();
			// 				};
			// 				setTimeout(resolve, 1000);
			// 			});
			// 			if (img.complete && img.naturalWidth !== 0) {
			// 				if (imageY + maxImageHeight > doc.internal.pageSize.getHeight() - 20) {
			// 					doc.addPage();
			// 					yPosition = addHeaderToPage();
			// 					imageY = yPosition;
			// 				}
			// 				doc.addImage(img, "JPEG", imageX, imageY, maxImageWidth, maxImageHeight);
			// 				imageY += maxImageHeight + 10;
			// 			}
			// 		}
			// 		yPosition = Math.max(tableEndY, imageY) -90;
			// 	} else {
			// 		yPosition = tableEndY + 7;
			// 	}
			// 	return yPosition;
			// };



			// const addSpecificationsSection = async (title, data, images) => {
			// 	if (yPosition > doc.internal.pageSize.getHeight() - 50) {
			// 		doc.addPage();
			// 		yPosition = addHeaderToPage();
			// 	}

			// 	doc.setFontSize(14);
			// 	doc.setFont("helvetica", "bold");
			// 	doc.text(title, margin, yPosition);
			// 	yPosition += 3;

			// 	const tableWidth = pageWidth * 0.5;
			// 	const body = Object.entries(data).map(([key, value]) => [key, value?.toString() || ""]);
 
			// 	const tableStartY = yPosition;
			// 	doc.autoTable({
			// 		startY: tableStartY,
			// 		body: body,
			// 		theme: 'grid',
			// 		margin: { left: margin, right: margin + tableWidth },
			// 		styles: { overflow: 'linebreak', cellPadding: 3, lineWidth: 0.3, lineColor: [0, 0, 0] },
			// 		columnStyles: { 0: { cellWidth: 35, fontStyle: 'bold', textColor: [0, 0, 0] } },
			// 		tableWidth: tableWidth,
			// 	});

			// 	const tableEndY = doc.lastAutoTable.finalY;
			// 	yPosition = tableEndY + 7;  

			// 	if (images && images.length > 0) {
			// 		const imageX = margin + tableWidth + 10;
			// 		let imageY = tableStartY;  
			// 		const maxImageWidth = 65;
			// 		const maxImageHeight = 35;

			// 		for (const imgPath of images) {
			// 			if (!imgPath) continue;
			// 			const img = new Image();
			// 			img.src = imageURL + imgPath;

			// 			await new Promise((resolve) => {
			// 				img.onload = resolve;
			// 				img.onerror = () => {
			// 					console.warn("Failed to load image:", imgPath);
			// 					resolve();
			// 				};
			// 				setTimeout(resolve, 1000);
			// 			});

			// 			if (img.complete && img.naturalWidth !== 0) {
			// 				if (imageY + maxImageHeight > doc.internal.pageSize.getHeight() - 20) {
			// 					doc.addPage();
			// 					yPosition = addHeaderToPage();
			// 					imageY = yPosition;
			// 				}
			// 				doc.addImage(img, "JPEG", imageX, imageY, maxImageWidth, maxImageHeight);
			// 				imageY += maxImageHeight + 10;
			// 			}
			// 		}
			// 	}

			// 	return yPosition;
			// };


			const addSpecificationsSection = async (title, data, images) => {
				if (yPosition > doc.internal.pageSize.getHeight() - 50) {
					doc.addPage();
					yPosition = addHeaderToPage();
				}
			
				// Add section title
				doc.setFontSize(14);
				doc.setFont("helvetica", "bold");
				doc.text(title, margin, yPosition);
				yPosition += 3;
			
				// Table setup
				const tableWidth = pageWidth * 0.5;
				const body = Object.entries(data).map(([key, value]) => [key, value?.toString() || ""]);
			
				const tableStartY = yPosition;
				doc.autoTable({
					startY: tableStartY,
					body: body,
					theme: 'grid',
					margin: { left: margin, right: margin + tableWidth },
					styles: { overflow: 'linebreak', cellPadding: 3, lineWidth: 0.3, lineColor: [0, 0, 0] },
					columnStyles: { 0: { cellWidth: 35, fontStyle: 'bold', textColor: [0, 0, 0] } },
					tableWidth: tableWidth,
				});
			
				const tableEndY = doc.lastAutoTable.finalY;
				yPosition = tableEndY + 7;  
			
				// Image handling — move images closer to the top
				if (images && images.length > 0) {
					const imageX = margin + tableWidth + 10;
					let imageY = tableStartY - 160;  // Adjust this value to move images closer to the top
					const maxImageWidth = 65;
					const maxImageHeight = 35;
			
					for (const imgPath of images) {
						if (!imgPath) continue;
						const img = new Image();
						img.src = imageURL + imgPath;
			
						await new Promise((resolve) => {
							img.onload = resolve;
							img.onerror = () => {
								console.warn("Failed to load image:", imgPath);
								resolve();
							};
							setTimeout(resolve, 1000);
						});
			
						if (img.complete && img.naturalWidth !== 0) {
							// Add new page if needed
							if (imageY + maxImageHeight > doc.internal.pageSize.getHeight() - 20) {
								doc.addPage();
								yPosition = addHeaderToPage();
								imageY = yPosition; // Reset to top of new page
							}
							doc.addImage(img, "JPEG", imageX, imageY, maxImageWidth, maxImageHeight);
							imageY += maxImageHeight + 10;  // Adjust spacing between images
						}
					}
				}
			
				return yPosition;
			};
			
			



			 


			



			// const addSpecificationsSection = async (title, data, images) => {
			// 	if (yPosition > doc.internal.pageSize.getHeight() - 50) {
			// 		doc.addPage();
			// 		yPosition = addHeaderToPage();
			// 	}

			// 	doc.setFontSize(14);
			// 	doc.setFont("helvetica", "bold");
			// 	doc.text(title, margin, yPosition);
			// 	yPosition += 3;

			// 	const tableWidth = pageWidth * 0.5;
			// 	const body = Object.entries(data).map(([key, value]) => [key, value?.toString() || ""]);

			// 	doc.autoTable({
			// 		startY: yPosition,
			// 		body: body,
			// 		theme: 'grid',
			// 		margin: { left: margin, right: margin + tableWidth },
			// 		styles: { overflow: 'linebreak', cellPadding: 3, lineWidth: 0.3, lineColor: [0, 0, 0] },
			// 		columnStyles: { 0: { cellWidth: 35, fontStyle: 'bold', textColor: [0, 0, 0] } },
			// 		tableWidth: tableWidth,
			// 	});

			// 	const tableEndY = doc.lastAutoTable.finalY;

			// 	if (images && images.length > 0) {
			// 		const imageX = margin + tableWidth + 10;
			// 		let imageY = yPosition;
			// 		const maxImageWidth = 65;
			// 		const maxImageHeight = 35;

			// 		for (const imgPath of images) {
			// 			if (!imgPath) continue;

			// 			const img = new Image();
			// 			img.src = imageURL + imgPath;

			// 			await new Promise((resolve) => {
			// 				img.onload = resolve;
			// 				img.onerror = () => {
			// 					console.warn("Failed to load image:", imgPath);
			// 					resolve();
			// 				};
			// 				setTimeout(resolve, 1000);
			// 			});

			// 			if (img.complete && img.naturalWidth !== 0) {
			// 				// Check if the image will overflow the page
			// 				if (imageY + maxImageHeight > doc.internal.pageSize.getHeight() - 20) {
			// 					// Move to the next page if the image exceeds the current page height
			// 					doc.addPage();
			// 					yPosition = addHeaderToPage();
			// 					imageY = yPosition; // Reset the image Y position to the top of the new page
			// 				}

			// 				// Add image to the page
			// 				doc.addImage(img, "JPEG", imageX, imageY, maxImageWidth, maxImageHeight);
			// 				imageY += maxImageHeight + 10; // Update Y position for the next image
			// 			}
			// 		}

			// 		// Reset yPosition to 0 if images move to a new page
			// 		if (imageY > tableEndY) {
			// 			yPosition = imageY - 91;
			// 		} else {
			// 			yPosition = tableEndY + 7;
			// 		}

			// 		// Reset yPosition to 0 if images are on a new page
			// 		if (imageY > doc.internal.pageSize.getHeight()) {
			// 			yPosition = 0; // Reset yPosition when a new page starts
			// 		}
			// 	} else {
			// 		yPosition = tableEndY + 7;
			// 	}

			// 	return yPosition;
			// };



			const endPlateData = {};


			if (lookUpList && lookUpList["epMaterial"] && endPlateMaterial) {
				endPlateData["Material"] = lookUpList["epMaterial"]
					.filter(item => endPlateMaterial.includes(item.id))
					.map(item => item.lkp_value)
					.join(", ");
			}

			if (lookUpList && lookUpList["epModal"] && endPlateModel) {
				endPlateData["Model"] = lookUpList["epModal"]
					.filter(item => endPlateModel.includes(item.id))
					.map(item => item.lkp_value)
					.join(", ");
			}


			if (lookUpList && lookUpList["oreientation"] && endPlateOrientation) {
				endPlateData["Orientation"] = lookUpList["oreientation"]
					.filter(item => endPlateOrientation.includes(item.id))
					.map(item => item.lkp_value)
					.join(", ");
			}

			if (lookUpList && lookUpList["coverDetail"] && coverDetail) {
				endPlateData["Cover Type"] = lookUpList["coverDetail"]
					.filter(item => item && coverDetail.includes(item.id))
					.map(item => item.sublkp_val)
					.join(", ") || "N/A";
			}
			if (Object.keys(endPlateData).length > 0) {
	 
				addSpecificationsSection("End Plate", endPlateData);
			}
			

			const pipeData = {};

			if (lookUpList && lookUpList["pipeType"] && pipeType) {
				pipeData["Pipe Type"] = lookUpList["pipeType"]
					.filter(item => pipeType.includes(item.id))
					.map(item => item.lkp_value)
					.join(", ");
			}



			const pipeBendTypes = [];

			if (pbStraight === "true") pipeBendTypes.push("Straight");
			if (pbSingle === "true") pipeBendTypes.push("Single");
			if (pbCross === "true") pipeBendTypes.push("Cross");
			if (pbOther === "true") pipeBendTypes.push("Other");

			if (pipeBendTypes.length > 0) {
				pipeData["Pipe Bend"] = pipeBendTypes.join(", ");
			}

			if (Object.keys(pipeData).length > 0) {
			 
				addSpecificationsSection("Pipe", pipeData);
			}

			if (finPerInch) {
				const finsData = {
					"Fin per Inch": finPerInch,
					"Fin Thickness": getThickValue(finPerInch) || "N/A"
				};
				addSpecificationsSection("Fins", finsData);
			}

			const brazingData = {};

			if (lookUpList && lookUpList["circuitModel"] && circuitModels) {
				brazingData["Circuit Model"] = lookUpList["circuitModel"]
					.filter(item => circuitModels.indexOf(item.id) !== -1)
					.map(item => item.lkp_value)
					.join(", ");
			}

			if (noCircuit) {
				brazingData["No of circuit"] = noCircuit;
			}

			if (lookUpList && lookUpList["liquidLine"] && liquidLine) {
				brazingData["Liquid Line"] = lookUpList["liquidLine"]
					.filter(item => liquidLine.indexOf(item.id) !== -1)
					.map(item => item.lkp_value)
					.join(", ");
			}

			if (lookUpList && lookUpList["dischargeLine"] && dischargeLine) {
				brazingData["Discharge Line"] = lookUpList["dischargeLine"]
					.filter(item => dischargeLine.indexOf(item.id) !== -1)
					.map(item => item.lkp_value)
					.join(", ");
			}


			if (Object.keys(brazingData).length > 0) {
				addSpecificationsSection("Brazing", brazingData);
			}

			const paintData = {};

			if (lookUpList && lookUpList["paintType"] && paintType) {
				paintData["Paint"] = lookUpList["paintType"]
					.filter(item => paintType.includes(item.id))
					.map(item => item.lkp_value)
					.join(", ");
			}

			if (lookUpList && lookUpList["packingType"] && packingType) {
				paintData["Packing"] = lookUpList["packingType"]
					.filter(item => packingType.includes(item.id))
					.map(item => item.lkp_value)
					.join(", ");
			}

			if (lookUpList && lookUpList["dispatchMode"] && dispatchMode) {
				paintData["Dispatch"] = lookUpList["dispatchMode"]
					.filter(item => dispatchMode.includes(item.id))
					.map(item => item.lkp_value)
					.join(", ");
			}

			// if (Object.keys(paintData).length > 0) {
			// 	addSpecificationsSection("Paint, Packing and Dispatch", paintData);
			// }

			let endPlateImages = epPhoto?.length ? epPhoto : [];
			let assemblyImages = assemblyPhoto?.length ? assemblyPhoto : [];
			let brazingImages = brazingPhoto?.length ? brazingPhoto : [];
			let allImages = [...endPlateImages, ...assemblyImages, ...brazingImages];

			if (Object.keys(paintData).length > 0) {
				yPosition = await addSpecificationsSection("Paint, Packing and Dispatch", paintData, allImages);
			}


			doc.addPage();
			yPosition = addHeaderToPage();

			doc.setFontSize(16);
			doc.setFont("helvetica", "bold");
			doc.text("Coil Details", margin, yPosition);
			yPosition += 8;

			const formatDateIst = (dateString) => {
				if (!dateString) return " ";
				try {
					const date = new Date(dateString);
					return date instanceof Date && !isNaN(date)
						? date.toLocaleString('en-IN', {
							timeZone: 'Asia/Kolkata',
							day: '2-digit',
							month: '2-digit',
							year: 'numeric',
							hour: '2-digit',
							minute: '2-digit'
						})
						: "Invalid Date";
				} catch (error) {
					console.error("Date formatting error:", error);
					return "Error";
				}
			};


			const processDetails = [
				{ process: "CNC Nesting", date: formatDateIst(cncNestingDate), comments: "" },
				{ process: "CNC Program Created", date: formatDateIst(cncNestingStatusDate), comments: `Program Number-${cncNestingPgm || ""}` },
				{ process: "CNC end plate punching", date: formatDateIst(cncPunchingDate), comments: "" },
				{ process: "End plate bending", date: formatDateIst(bendingDate), comments: "" },
				{ process: "Tray/Fan Cover Bending", date: formatDateIst(bendingDate), comments: `Comments: ${epComments || ""}` },
				{ process: "Copper tube Cutting", date: formatDateIst(tCuttingDate), comments: `Roll Number: ${tCuttingRollNo || ""}` },
				{ process: "Copper tube Bending", date: formatDateIst(bendingDate), comments: `Comments: ${pipeComment || ""}` },
				{ process: "Fins Punching", date: formatDateIst(finPunchDate), comments: `Foil Number: ${finFoilNo || "No Roll No"}` },
				{ process: "Coil Assembly", date: formatDateIst(caStatusDate), comments: `Comments: ${finComments || ""}` },
				{ process: "Coil Expansion", date: formatDateIst(brazingExpansion), comments: `Expansion Type: ${lookUpList["expansionType"]?.filter(item => expansionType?.includes(item.id)).map(item => item.lkp_value).join(", ") || ""}` },
				{ process: "Coil Brazing & Leak Testing", date: formatDateIst(brazingDate), comments: `Comments: ${brazingComments || ""}` },
				{ process: "Painting", date: formatDateIst(ppDate), comments: `Painting Type: ${lookUpList["paintType"]?.filter(item => paintType?.includes(item.id)).map(item => item.lkp_value).join(", ") || ""}` },
				{ process: "Packing", date: formatDateIst(ppStatusDate), comments: `Packing Type: ${lookUpList["packingType"]?.filter(item => packingType?.includes(item.id)).map(item => item.lkp_value).join(", ") || ""}` },
				{ process: "Dispatch", date: formatDateIst(dispatchDate), comments: dispatchComment || "" }
			];

			doc.autoTable({
				startY: yPosition,
				head: [["Process", "Date & Time", "Comments"]],
				body: processDetails.map(item => [item.process, item.date, item.comments]),
				theme: 'grid',
				margin: { left: margin, right: margin },
				styles: { overflow: 'linebreak', cellPadding: 3, lineWidth: 0.3, lineColor: [0, 0, 0] },
				headStyles: { fillColor: [255, 255, 255], fontStyle: 'bold', textColor: [0, 0, 0] },
				columnStyles: {
					0: { cellWidth: 70, fontStyle: 'bold', textColor: [0, 0, 0] },
					1: { cellWidth: 50 },
				},

			});

			yPosition = doc.lastAutoTable.finalY + 10;



			// if (brazingDetails && brazingDetails.length > 0) {
			// 	doc.addPage();
			// 	yPosition = addHeaderToPage();

			// 	doc.setFontSize(16);
			// 	doc.setFont("helvetica", "bold");
			// 	doc.text("Brazing & Leak Testing Details", margin, yPosition);
			// 	yPosition += 10;


			// 	for (let i = 0; i < brazingDetails.length; i++) {
			// 		const detail = brazingDetails[i];

			// 		if (!detail) continue; // Skip if detail is undefined

			// 		if (yPosition > doc.internal.pageSize.getHeight() - 80) {
			// 			doc.addPage();
			// 			yPosition = addHeaderToPage();
			// 		}

			// 		doc.setFontSize(12);
			// 		doc.text(`Table ${i + 1}`, margin, yPosition);
			// 		yPosition += 5;


			// 		const leakDetailsArray = [];
			// 		if (detail.A && detail.A !== "0") leakDetailsArray.push(`A:${detail.A}`);
			// 		if (detail.B && detail.B !== "0") leakDetailsArray.push(`B:${detail.B}`);
			// 		if (detail.C && detail.C !== "0") leakDetailsArray.push(`C:${detail.C}`);
			// 		if (detail.D && detail.D !== "0") leakDetailsArray.push(`D:${detail.D}`);


			// 		const workmanDetailsArray = [];
			// 		if (detail.uBend) workmanDetailsArray.push(`U-Bend:${detail.uBend}`);
			// 		if (detail.inletOutlet) workmanDetailsArray.push(`Inlet-Outlet:${detail.inletOutlet}`);
			// 		if (detail.header) workmanDetailsArray.push(`Header:${detail.header}`);
			// 		if (detail.distributor) workmanDetailsArray.push(`Distributor:${detail.distributor}`);

			// 		const brazingData = {
			// 			"Serial No": detail.series_ref || "N/A",
			// 			"Leak Date": formatDateIst(detail.completion),
			// 			"Leak Details": leakDetailsArray.length > 0 ? leakDetailsArray.join(" ") : "None",
			// 			"Workman Details": workmanDetailsArray.length > 0 ? workmanDetailsArray.join(" ") : "None"
			// 		};

			// 		doc.autoTable({
			// 			startY: yPosition,
			// 			body: Object.entries(brazingData).map(([key, value]) => [key, value]),
			// 			theme: 'grid',
			// 			margin: { left: margin, right: margin },
			// 			styles: { overflow: 'linebreak', cellPadding: 3 },
			// 			columnStyles: { 0: { cellWidth: 50, fontStyle: 'bold' } },
			// 			tableWidth: 100
			// 		});

			// 		yPosition = doc.lastAutoTable.finalY + 15;


			// 		if (brazingTestingImages && brazingTestingImages[detail.series_id] &&
			// 			Array.isArray(brazingTestingImages[detail.series_id]) &&
			// 			brazingTestingImages[detail.series_id].length > 0) {

			// 			const images = brazingTestingImages[detail.series_id];

			// 			for (const imgPath of images) {
			// 				if (!imgPath) continue;  

			// 				try {
			// 					const img = new Image();
			// 					img.src = imageURL + imgPath;


			// 					await new Promise((resolve) => {
			// 						img.onload = resolve;
			// 						img.onerror = () => {
			// 							console.warn(`Failed to load image: ${imgPath}`);
			// 							resolve();
			// 						};
			// 						setTimeout(resolve, 1500);  
			// 					});


			// 					if (img.complete && img.naturalWidth !== 0) {
			// 						const imgWidth = 80;
			// 						const imgHeight = 60;

			// 						if (yPosition + imgHeight > doc.internal.pageSize.getHeight() - 20) {
			// 							doc.addPage();
			// 							yPosition = addHeaderToPage();
			// 						}

			// 						doc.addImage(img, 'JPEG', margin, yPosition, imgWidth, imgHeight);
			// 						yPosition += imgHeight + 10;
			// 					}
			// 				} catch (imgError) {
			// 					console.error("Error loading image:", imgError, imgPath);

			// 				}
			// 			}
			// 		}
			// 	}
			// }


			if (brazingDetails && brazingDetails.length > 0) {
				doc.addPage();
				yPosition = addHeaderToPage();

				doc.setFontSize(16);
				doc.setFont("helvetica", "bold");
				doc.text("Brazing & Leak Testing Details", margin, yPosition);
				yPosition += 5;

				for (let i = 0; i < brazingDetails.length; i++) {
					const detail = brazingDetails[i];

					if (!detail) continue;

					if (yPosition > doc.internal.pageSize.getHeight() - 80) {
						doc.addPage();
						yPosition = addHeaderToPage();
					}

					doc.setFontSize(12);
					doc.text(`Table ${i + 1}`, margin, yPosition);
					yPosition += 3;

					const leakDetailsArray = [];
					if (detail.A && detail.A !== "0") leakDetailsArray.push(`A:${detail.A}`);
					if (detail.B && detail.B !== "0") leakDetailsArray.push(`B:${detail.B}`);
					if (detail.C && detail.C !== "0") leakDetailsArray.push(`C:${detail.C}`);
					if (detail.D && detail.D !== "0") leakDetailsArray.push(`D:${detail.D}`);

					const workmanDetailsArray = [];
					if (detail.uBend) workmanDetailsArray.push(`U-Bend:${detail.uBend}`);
					if (detail.inletOutlet) workmanDetailsArray.push(`Inlet-Outlet:${detail.inletOutlet}`);
					if (detail.header) workmanDetailsArray.push(`Header:${detail.header}`);
					if (detail.distributor) workmanDetailsArray.push(`Distributor:${detail.distributor}`);

					const brazingData = {
						"Serial No": detail.series_ref || " ",
						"Leak Date": formatDateIst(detail.completion),
						"Leak Details": leakDetailsArray.length > 0 ? leakDetailsArray.join(" ") : " ",
						"Workman Details": workmanDetailsArray.length > 0 ? workmanDetailsArray.join(" ") : " "
					};

					doc.autoTable({
						startY: yPosition,
						body: Object.entries(brazingData).map(([key, value]) => [key, value]),
						theme: 'grid',
						margin: { left: margin, right: margin },
						styles: { overflow: 'linebreak', cellPadding: 3, lineWidth: 0.3, lineColor: [0, 0, 0] },
						columnStyles: { 0: { cellWidth: 50, fontStyle: 'bold', textColor: [0, 0, 0] } },
						tableWidth: 100,

					});

					let tableEndY = doc.lastAutoTable.finalY;
					let imageX = margin + 120;
					let imageY = yPosition;

					if (brazingTestingImages && brazingTestingImages[detail.series_id] &&
						Array.isArray(brazingTestingImages[detail.series_id]) &&
						brazingTestingImages[detail.series_id].length > 0) {

						const images = brazingTestingImages[detail.series_id];

						for (const imgPath of images) {
							if (!imgPath) continue;

							try {
								const img = new Image();
								img.src = imageURL + imgPath;

								await new Promise((resolve) => {
									img.onload = resolve;
									img.onerror = () => {
										console.warn(`Failed to load image: ${imgPath}`);
										resolve();
									};
									setTimeout(resolve, 1500);
								});

								if (img.complete && img.naturalWidth !== 0) {
									const imgWidth = 60;
									const imgHeight = 35;

									if (imageY + imgHeight > doc.internal.pageSize.getHeight() - 1) {
										doc.addPage();
										yPosition = addHeaderToPage();
										imageY = yPosition;
									}

									doc.addImage(img, 'JPEG', imageX, imageY, imgWidth, imgHeight);
									imageY += imgHeight + 10;
								}
							} catch (imgError) {
								console.error("Error loading image:", imgError, imgPath);
							}
						}
					}

					yPosition = Math.max(tableEndY, imageY) + 10;
				}
			}

			doc.save(`Order_${orderNo}.pdf`);
			return true;
		} catch (error) {
			console.error("Error generating PDF:", error);
			alert("Failed to generate PDF. Please try again.");
			return false;
		}
	};





	// 	const generatePDF = (orderNo) => {
	// 		const doc = new jsPDF({
	// 			orientation: 'portrait',
	// 			unit: 'px',
	// 			format: 'a4',
	// 		});

	// 		const element = document.getElementById('orderDetails');
	// 		console.log(element)



	// 		if (!element) {
	// 			console.error('Order details element not found');
	// 			return;
	// 		}


	// 		const tempStyle = document.createElement('style');
	// 		tempStyle.innerHTML = `
	// 		  .heading {
	// 			font-weight: bold;
	// 			font-size: 24px;
	// 		  }
	// 		  #orderDetails {
	// 			max-width: 100%;
	// 			overflow: hidden;
	// 		  }
	// 		  #orderDetails table {
	// 		    page-break-inside: auto;
	// 			margin-top: 10px;
	// 			width: 100%;
	// 			border-collapse: collapse;

	// 		  }
	// 		  #orderDetails td, #orderDetails th {
	// 			text-align: center;
	// 			vertical-align: middle;
	// 			padding: 12px;
	// 			border: 1px solid black;
	// 			font-size: 20px;
	// 		  }
	// 		  #orderDetails th {
	// 			font-weight: bold;
	// 			font-size: 22px;
	// 			background-color: #f0f0f0;
	// 		  }
	// 		  #orderDetails img {
	//             //  width: 450px;  
	//             //  height: 350px; 
	// 			 width: auto;
	//              height: auto;
	//              display: block;
	//              margin: 10px auto;
	//              object-fit: contain;  
	//              page-break-inside: auto;
	//         }
	// .page-break {
	//   page-break-before: always;
	//     break-before: page;
	// }

	// 		`;
	// 		document.head.appendChild(tempStyle);

	// 		const pageWidth = doc.internal.pageSize.getWidth();

	// 		doc.html(element, {
	// 			callback: function (doc) {
	// 				document.head.removeChild(tempStyle);
	// 				doc.save(`order_${orderNo}.pdf`);
	// 			},
	// 			width: pageWidth - 50,
	// 			windowWidth: element.scrollWidth,
	// 			html2canvas: {
	// 				scale: 0.38,
	// 				useCORS: true,
	// 				allowTaint: true,
	// 				logging: false,
	// 			},
	// 			autoPaging: 'text',
	// 		});
	// 	};

	// const generatePDF = (orderNo) => {
	// 	const doc = new jsPDF({
	// 		orientation: 'portrait',
	// 		unit: 'px',
	// 		format: 'a4',
	// 	});

	// 	const element = document.getElementById('orderDetails');
	// 	console.log(element);

	// 	const images = Array.from(element.querySelectorAll('img'))
	// 		.filter(img => img.classList.contains('MuiImageListItem-img'));

	// 	let imagePromises = images.map(img => toBase64(img.src, 0.3));

	// 	const tempStyle = document.createElement('style');
	// 	tempStyle.innerHTML = `
	// 		.heading {
	// 			font-weight: bold !important;
	// 			font-size: 24px !important;
	// 			white-space: nowrap !important;        
	// 			letter-spacing: 0 !important;            
	// 			word-spacing: 0 !important; 
	// 		}
	// 		#orderDetails table {
	// 			margin-top: 10px !important;
	// 			width: 90% !important;
	// 			border-collapse: collapse !important;
	// 		}
	// 		#orderDetails td, #orderDetails th {
	// 			text-align: center !important;
	// 			vertical-align: middle !important;
	// 			padding: 12px !important;
	// 			border: 1px solid black !important;
	// 			font-size: 20px !important;
	// 		}
	// 		#orderDetails th {
	// 			font-weight: bold !important;
	// 			font-size: 22px !important;
	// 			background-color: #f0f0f0 !important;
	// 		}

	// 		// #orderDetails img {
	// 		// 	display: none;
	// 		// }
	// 	`;
	// 	document.head.appendChild(tempStyle);

	// 	Promise.all(imagePromises)
	// 		.then((base64Images) => {
	// 			let pageWidth = doc.internal.pageSize.getWidth();
	// 			console.log(pageWidth);
	// 			let pageHeight = doc.internal.pageSize.getHeight();
	// 			console.log(pageHeight);

	// 			doc.html(element, {
	// 				callback: function (doc) {
	// 					document.head.removeChild(tempStyle);

	// 					if (base64Images.length > 0) {
	// 						doc.addPage();

	// 						let imgWidth = 200;
	// 						let imgHeight = 170;
	// 						let margin = 10;
	// 						let columns = 2;
	// 						let startX = margin;
	// 						let startY = 20;
	// 						let x = startX;
	// 						let y = startY;

	// 						base64Images.forEach((base64, index) => {
	// 							if (y + imgHeight > pageHeight - 30) {  
	// 								doc.addPage();  
	// 								y = startY;  
	// 							}
	// 							doc.addImage(base64, 'JPEG', x, y, imgWidth, imgHeight);
	// 							x += imgWidth + margin;
	// 							if (x + imgWidth > pageWidth - margin) {
	// 								x = startX;
	// 								y += imgHeight + margin;
	// 							}
	// 						});
	// 					}

	// 					doc.save(`order_${orderNo}.pdf`);
	// 				},
	// 				width: pageWidth - 100,
	// 				html2canvas: {
	// 					scale: 0.38,
	// 					useCORS: true,
	// 					allowTaint: true,
	// 				},
	// 			});
	// 		})
	// 		.catch((error) => {
	// 			let pageWidth = doc.internal.pageSize.getWidth();

	// 			doc.html(element, {
	// 				callback: function (doc) {
	// 					doc.save(`order_${orderNo}.pdf`);
	// 					document.head.removeChild(tempStyle);
	// 				},
	// 				width: pageWidth - 100,
	// 				html2canvas: {
	// 					scale: 0.38,
	// 					useCORS: true,
	// 					allowTaint: true,
	// 				},
	// 			});
	// 		});
	// };






	// const toBase64 = (url, quality = 1) => {
	// 	return new Promise((resolve, reject) => {
	// 		const img = new Image();
	// 		img.crossOrigin = 'Anonymous';
	// 		img.onload = function () {
	// 			const canvas = document.createElement('canvas');
	// 			canvas.width = img.width;
	// 			canvas.height = img.height;
	// 			const ctx = canvas.getContext('2d');
	// 			ctx.drawImage(img, 0, 0);
	// 			resolve(canvas.toDataURL('image/jpeg', quality));
	// 		};
	// 		img.onerror = reject;
	// 		img.src = url;
	// 	});
	// };

	const handleSearchOrderId = (orderId) => {
		orderRowID = orderId.id;
		handleOrderinfo1();
	};

	const getThickValue = (finPerInch) => {
		const mapper = {
			T: "0.15mm Thick",
			H: "0.13mm Thick",
			B: "0.12mm Thick Hydrophilic blue Aluminum",
			C: "0.12mm Thick Copper Fins",
			S: "0.105mm Thick",
			U: "0.20mm Thick",
			H: "0.13mm Thick",
			I: "0.13mm Thick Hydrophilic Blue Aluminium",
			PP: "Not Applicable",
		};
		if (finPerInch.includes("-")) {
			finPerInch = finPerInch.split("-")[0].trim().toUpperCase();
		}
		if (typeof finPerInch === "string") {
			finPerInch = finPerInch.toUpperCase();
		}
		if (mapper[finPerInch]) {
			return mapper[finPerInch];
		} else {
			// If value is not found in mapper, return a default value
			return "0.12mm Thick"; // You can set your default value here
		}
	};


	const formatDateIst = (date) => {
		if (date != "" && date != null && date != "undefined") {
			const time = date.split(" ")[1] ?? "";
			let newDate = new Date(date);
			let month = newDate.getMonth() + 1;
			month = month < 10 ? "0" + month : month;
			return (
				newDate.getDate() +
				"-" +
				month +
				"-" +
				newDate.getFullYear() +
				" " +
				time
			);
		}
		return "";
	};


	useEffect(() => {
		handleSqFeet();
		handleSize();
	}, [length, height, row, quantity, handleSqFeet, handleSize]);

	useEffect(() => {
		if (prop.orderId !== null || prop.orderId !== 0) {
			handleGetLookup();
			handleOrderinfo1();
		}
	}, [prop.orderId]);
	const fixedLayout = {
		tableLayout: "fixed",
	};
	return (
		<div >

			<>
				<Container key={"orderView"} id="orderDetails" >
					<Grid container spacing={3}>
						<Grid
							item
							xs={12}
							style={{ display: "flex", justifyContent: "center" }}
						>
							<AutoCompleteOrder
								access={access}
								onSearchOrderId={handleSearchOrderId}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={4} id="print-ignore">
						<Grid item xs={6}>
							<img
								src={logo}
								alt="Logo"
								style={{ width: "50%", height: "auto" }}
							/>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: "flex",
								justifyContent: "flex-end",
								alignItems: "center",
							}}
						>
							<Box >
								<Button
									style={{ backgroundColor: "#C5552C", color: "white" }}
									onClick={() => {
										generatePDF(orderNo);
									}}
									// onClick={() => {
									//     printPage();
									// }}
									variant="contained"
									startIcon={<PrintIcon />}
								>
									Print
								</Button>
							</Box>
						</Grid>
					</Grid>
					<Grid container spacing={2} columnGap={2} className="print-header">
						<Grid
							item
							xs={12}
							style={{ display: "flex", justifyContent: "center" }}
						>
							<img
								src={logo}
								alt="Logo"
								style={{ width: "50%", height: "50%" }}
							/>
						</Grid>
					</Grid>
					<Grid
						container
						spacing={2}
						columnSpacing={2}
						columnGap={2}
						className="container"
						style={{
							padding: "10px",
							marginTop: "1.5em",
							tableLayout: "fixed",
						}}

					>
						<Typography className="heading" variant="h5">Order Details</Typography>
						<table
							border={1}
							cellPadding={1}
							cellSpacing={2}
							style={{ width: "inherit" }}

						>
							<thead>

								<tr>
									<th>Order No</th>
									<th>Date</th>
									<th>Customer</th>
									<th>Size</th>
									<th>Sq Feet</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>{orderNo + splitId}</td>
									<td>{orderDate}</td>
									<td>{customerName}</td>
									<td>{size}</td>
									<td>{sqfeet}</td>
								</tr>
							</tbody>
						</table>
					</Grid>
					<Grid container spacing={2} sx={fixedLayout}  >
						<Grid item xs={6}>
							<Grid container spacing={2}>
								<Grid item xs={12}   >
									<Typography className="heading" variant="h6">End Plate</Typography>
									<table style={{ tableLayout: "fixed" }}>
										<tbody>
											<tr>
												<th style={{ textAlign: "left", paddingLeft: "10px" }}>
													Material
												</th>
												<td style={{ textAlign: "left", paddingLeft: "10px" }}>
													{lookUpList["epMaterial"]?.map((item) => {
														return (
															<>
																{endPlateMaterial &&
																	endPlateMaterial.includes(item.id)
																	? item.lkp_value
																	: ""}
															</>
														);
													})}
												</td>
											</tr>
											<tr>
												<th style={{ textAlign: "left", paddingLeft: "10px" }}>
													Model
												</th>
												<td style={{ textAlign: "left", paddingLeft: "10px" }}>
													{lookUpList["epModal"]?.map((item) => {
														return (
															<>
																{endPlateModel &&
																	endPlateModel.includes(item.id)
																	? item.lkp_value
																	: ""}
															</>
														);
													})}
												</td>
											</tr>
											<tr>
												<th style={{ textAlign: "left", paddingLeft: "10px" }}>
													Orientation
												</th>
												<td style={{ textAlign: "left", paddingLeft: "10px" }}>
													{lookUpList["oreientation"]?.map((item) => {
														return (
															<>
																{endPlateOrientation &&
																	endPlateOrientation.includes(item.id)
																	? item.lkp_value
																	: ""}
																<br />
															</>
														);
													})}
												</td>
											</tr>
											<tr>
												<th style={{ textAlign: "left", paddingLeft: "10px" }}>
													Cover Type
												</th>
												<td style={{ textAlign: "left", paddingLeft: "10px" }}>
													<Stack direction="row"  >
														{lookUpList["coverType"]?.map((item) => {
															return (
																<>
																	{lookUpList["coverDetail"]?.map((itemd) => {
																		if (
																			coverDetail !== undefined &&
																			itemd.lkp_id === item.id &&
																			coverDetail.indexOf(itemd.id) !== -1
																		) {
																			return (
																				<>
																					{itemd.sublkp_val}
																				</>
																			);
																		}
																	})}
																</>
															);
														})}
													</Stack>
												</td>
											</tr>
										</tbody>
									</table>
								</Grid>
								<Grid item xs={12}>
									<Typography className="heading" variant="h6">Pipe</Typography>
									<table id="specificTable" style={{ tableLayout: "fixed" }} >
										<tbody>
											<tr>
												<th style={{ textAlign: "left", paddingLeft: "10px" }}>
													Pipe Type:
												</th>
												<td style={{ textAlign: "left", paddingLeft: "10px" }}>
													{lookUpList["pipeType"]?.map((item) => {
														return (
															<>
																{pipeType && pipeType.includes(item.id)
																	? item.lkp_value
																	: null}
															</>
														);
													})}
												</td>
											</tr>

											<tr>
												<th style={{ textAlign: "left", paddingLeft: "10px" }}>
													Pipe Bend:
												</th>
												<td style={{ textAlign: "left", paddingLeft: "10px" }}>
													<Stack
														direction="row"
														spacing={1}
														alignContent="space-between"
													>
														{pbStraight === "true" && (
															<Typography variant="subtitle1" gutterBottom>
																Straight
															</Typography>
														)}
														{pbSingle === "true" && (
															<Typography variant="subtitle1" gutterBottom>
																Single
															</Typography>
														)}
														{pbCross === "true" && (
															<Typography variant="subtitle1" gutterBottom>
																Cross
															</Typography>
														)}
														{pbOther === "true" && (
															<Typography variant="subtitle1" gutterBottom>
																Other
															</Typography>
														)}
													</Stack>
												</td>
											</tr>
										</tbody>
									</table>
								</Grid>
								<Grid item xs={12}>
									<Typography className="heading" variant="h6">Fins</Typography>
									<table id="specificTable" style={{ tableLayout: "fixed" }}>
										<tbody>
											<tr>
												<th style={{ textAlign: "left", paddingLeft: "10px" }}>
													Fin per Inch
												</th>
												<td style={{ textAlign: "left", paddingLeft: "10px" }}>
													{finPerInch}
												</td>
											</tr>
											<tr>
												<th style={{ textAlign: "left", paddingLeft: "10px" }}>
													Fin Thickness
												</th>
												<td style={{ textAlign: "left", paddingLeft: "10px" }}>
													{getThickValue(finPerInch)}
												</td>
											</tr>
										</tbody>
									</table>
								</Grid>
								<Grid item xs={12}>
									<Typography className="heading" variant="h6">Brazing</Typography>
									<table style={{ tableLayout: "fixed" }}>
										<tbody>
											<tr>
												<th style={{ textAlign: "left", paddingLeft: "10px" }}>
													Circuit Model
												</th>
												<td style={{ textAlign: "left", paddingLeft: "10px" }}>
													{lookUpList["circuitModel"]?.map((item) => {
														return (
															<>
																{circuitModels &&
																	circuitModels.indexOf(item.id) !== -1
																	? item.lkp_value
																	: ""}
															</>
														);
													})}
												</td>
											</tr>
											<tr>
												<th style={{ textAlign: "left", paddingLeft: "10px" }}>
													No of circuit
												</th>
												<td style={{ textAlign: "left", paddingLeft: "10px" }}>
													{noCircuit}
												</td>
											</tr>
											<tr>
												<th style={{ textAlign: "left", paddingLeft: "10px" }}>
													Liquid Line
												</th>
												<td style={{ textAlign: "left", paddingLeft: "10px" }}>
													{lookUpList["liquidLine"]?.map((item) => {
														return (
															<>
																{liquidLine &&
																	liquidLine.indexOf(item.id) !== -1 &&
																	item.lkp_value}
															</>
														);
													})}
												</td>
											</tr>
											<tr>
												<th style={{ textAlign: "left", paddingLeft: "10px" }}>
													Discharge Line
												</th>
												<td style={{ textAlign: "left", paddingLeft: "10px" }}>
													{lookUpList["dischargeLine"]?.map((item) => {
														return (
															<>
																{dischargeLine &&
																	dischargeLine.indexOf(item.id) !== -1
																	? item.lkp_value
																	: ""}
															</>
														);
													})}
												</td>
											</tr>
										</tbody>
									</table>
								</Grid>
								<Grid item xs={12}>
									<Typography className="heading" variant="h6">
										Paint,Packing and Dispatch
									</Typography>
									<table style={{ tableLayout: "fixed" }}>
										<tbody>
											<tr>
												<th style={{ textAlign: "left", paddingLeft: "10px" }}>
													Paint
												</th>
												<td style={{ textAlign: "left", paddingLeft: "10px" }}>
													{lookUpList["paintType"]?.map((item) => {
														return (
															<>
																{paintType && paintType.includes(item.id)
																	? item.lkp_value
																	: ""}
															</>
														);
													})}
												</td>
											</tr>

											<tr>
												<th style={{ textAlign: "left", paddingLeft: "10px" }}>
													Packing
												</th>
												<td style={{ textAlign: "left", paddingLeft: "10px" }}>
													{lookUpList["packingType"]?.map((item) => {
														return (
															<>
																{packingType && packingType.includes(item.id)
																	? item.lkp_value
																	: ""}
															</>
														);
													})}
												</td>
											</tr>
											<tr>
												<th style={{ textAlign: "left", paddingLeft: "10px" }}>
													Dispatch
												</th>
												<td style={{ textAlign: "left", paddingLeft: "10px" }}>
													{lookUpList["dispatchMode"]?.map((item) => {
														return (
															<>
																{dispatchMode && dispatchMode.includes(item.id)
																	? item.lkp_value
																	: ""}
															</>
														);
													})}
												</td>
											</tr>
										</tbody>
									</table>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={6}>
							<Grid container>
								<Grid item xs={12}>
									<>
										{
											<ImageList cols={1} rowHeight={200}>
												{epPhoto?.map((item, index) => (
													<ImageListItem key={"epphoto" + index}>
														<img
															src={imageURL + item}
															srcSet={imageURL + item}
															alt={"EpPhoto"}
															loading="lazy"
															style={{
																maxWidth: "100%",
																maxHeight: "100%",
																verticalAlign: "middle",
																objectFit: "contain",
															}}

														/>
														<IconButton
															className="order-view-img"
															onClick={() =>
																handleClickOpenimg(imageURL + item)
															}
														>
															<PreviewIcon />
														</IconButton>
													</ImageListItem>
												))}
											</ImageList>
										}
									</>
								</Grid>
								<Grid item xs={12}>
									<>
										{
											<ImageList cols={1} rowHeight={250}>
												{assemblyPhoto?.map((item, index) => (
													<ImageListItem key={"assembly" + index}>
														<img
															src={imageURL + item}
															srcSet={imageURL + item}
															alt={"assembly"}
															loading="lazy"
															style={{
																maxWidth: "100%",
																maxHeight: "100%",
																verticalAlign: "middle",
																objectFit: "contain",
															}}
														/>
														<IconButton
															className="order-view-img"
															onClick={() =>
																handleClickOpenimg(imageURL + item)
															}
														>
															<PreviewIcon />
														</IconButton>
													</ImageListItem>
												))}
											</ImageList>
										}
									</>
								</Grid>
								<Grid item xs={12}>
									<>
										{
											<ImageList cols={1} rowHeight={"25%"}>
												{brazingPhoto?.map((item, index) => (
													<ImageListItem key={"brazing" + index}>
														<img
															src={imageURL + item}
															srcSet={imageURL + item}
															alt={"Brazing"}
															loading="lazy"
															style={{
																maxWidth: "100%",
																maxHeight: "100%",
																verticalAlign: "middle",
																objectFit: "contain",
															}}
														/>
														<IconButton
															onClick={() =>
																handleClickOpenimg(imageURL + item)
															}
														>
															<PreviewIcon />
														</IconButton>
													</ImageListItem>
												))}
											</ImageList>
										}
									</>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<div className="print-page-break"></div>
					<Grid container spacing={2} columnGap={2} className="print-header">
						<Grid
							item
							xs={12}
							style={{ display: "flex", justifyContent: "center" }}
						>
							<img
								src={logo}
								alt="Logo"
								style={{ width: "50%", height: "50%" }}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} className="page-break" id="coilDetails">
						<Typography className="heading" variant="h5">Coil Details</Typography>
						<table style={{ tableLayout: "fixed" }}>
							<thead>
								<tr>
									<th>Process</th>
									<th>Date & Time</th>
									<th>Comments</th>
								</tr>
							</thead>
							<tbody>
								{
									<tr>
										<th style={{ textAlign: "left" }}>CNC Nesting</th>
										<td>{formatDateIst(cncNestingDate)}</td>
									</tr>
								}

								{
									<tr>
										<th style={{ textAlign: "left" }}>CNC Program Created</th>
										<td>{formatDateIst(cncNestingStatusDate)}</td>
										<td
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "flex-start",
												border: "0",
											}}
										>
											Program Number-{cncNestingPgm}
										</td>
									</tr>
								}

								{
									<tr>
										<th style={{ textAlign: "left" }}>
											CNC end plate punching
										</th>
										<td>{formatDateIst(cncPunchingDate)}</td>
									</tr>
								}

								{
									<tr>
										<th style={{ textAlign: "left" }}>End plate bending</th>
										<td>{formatDateIst(bendingDate)}</td>
									</tr>
								}

								{
									<tr>
										<th style={{ textAlign: "left" }}>
											Tray/Fan Cover Bending
										</th>
										<td>{formatDateIst(bendingDate)}</td>
										<td 
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "flex-start",
												border: "0",
											}}
										>
											Comments: {epComments}
										</td>
									</tr>
								}
								{
									<tr>
										<th style={{ textAlign: "left" }}>Copper tube Cutting</th>
										<td>{formatDateIst(tCuttingDate)}</td>
										<td
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "flex-start",
												border: "0",
											}}
										>
											<span>Roll Number: {tCuttingRollNo}</span>
											{pbStraight == "true" && (
												<span>
													Pipe Straight-{pbStraightQty} Nos {pbStraightSize}mm
												</span>
											)}
											{pbCross == "true" && (
												<span>
													Pipe Cross-{pbCrossQty} Nos {pbCrossSize}mm
												</span>
											)}
											{pbSingle == "true" && (
												<span>
													Pipe Single-{pbSingleQty} Nos {pbSingleSize}mm
												</span>
											)}
											{pbOther == "true" && (
												<span>
													Pipe Others-{pbOtherQty} Nos {pbOtherSize}mm
												</span>
											)}
										</td>
									</tr>
								}
								{
									<tr>
										<th style={{ textAlign: "left" }}>Copper tube Bending </th>
										<td>{formatDateIst(tCuttingDateTime)}</td>
										<td
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "flex-start",
												border: "0",
											}}
										>
											Comments-{pipeComment}
										</td>
									</tr>
								}
								{
									<tr>
										<th style={{ textAlign: "left" }}>Fins Punching</th>
										<td>{formatDateIst(finPunchDate)}</td>
										<td
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "flex-start",
												border: "0",
											}}
										>
											<span>
												Foil Number:{finFoilNo ? finFoilNo : " No Roll no"}
											</span>
										</td>
									</tr>
								}
								{
									<tr>
										<th style={{ textAlign: "left" }}>Coil Assembly</th>
										<td>{formatDateIst(caStatusDate)}</td>
										<td
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "flex-start",
												border: "0",
											}}
										>
											<span>Comments-{finComments}</span>
										</td>
									</tr>
								}
								{
									<tr>
										<th style={{ textAlign: "left" }}>Coil Expansion</th>
										<td>{formatDateIst(brazingExpansion)}</td>
										<td
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "flex-start",
												border: "0",
											}}
										>
											<span>
												Expansion Type:{" "}
												{lookUpList["expansionType"]?.map((item) => {
													return (
														<>
															{expansionType && expansionType.includes(item.id)
																? item.lkp_value
																: ""}
														</>
													);
												})}
											</span>
										</td>
									</tr>
								}
								{
									<tr>
										<th style={{ textAlign: "left" }}>
											Coil Brazing & Leak Testing
										</th>
										<td>{formatDateIst(brazingDate)}</td>
										<td
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "flex-start",
												border: "0",
											}}
										>
											{" "}
											Comments: {brazingComments}
										</td>
									</tr>
								}

								{
									<tr>
										<th style={{ textAlign: "left" }}>Painting</th>
										<td>{formatDateIst(ppDate)}</td>
										<td
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "flex-start",
												border: "0",
											}}
										>
											<span>
												Painting Type:{" "}
												{lookUpList["paintType"]?.map((item) => {
													return (
														<>
															{paintType && paintType.includes(item.id)
																? item.lkp_value
																: ""}
														</>
													);
												})}
											</span>
										</td>
									</tr>
								}
								{
									<tr>
										<th style={{ textAlign: "left" }}>Packing</th>
										<td>{formatDateIst(ppStatusDate)}</td>
										<td
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "flex-start",
												border: "0",
											}}
										>
											<span>
												Packing Type:{" "}
												{lookUpList["packingType"]?.map((item) => {
													return (
														<>
															{packingType && packingType.includes(item.id)
																? item.lkp_value
																: ""}
														</>
													);
												})}
											</span>
										</td>
									</tr>
								}
								{
									<tr>
										<th style={{ textAlign: "left" }}>Dispatch</th>
										<td>{formatDateIst(dispatchDate)}</td>
										<td
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "flex-start",
												border: "0",
											}}
										>
											{dispatchComment}
										</td>
									</tr>
								}
							</tbody>
						</table>
					</Grid>
					<div className="print-page-break"></div>
					<Grid container spacing={2} columnGap={2} className="print-header">
						<Grid
							item
							xs={12}
							style={{ display: "flex", justifyContent: "center" }}
						>
							<img
								src={logo}
								alt="Logo"
								style={{ width: "50%", height: "50%" }}
							/>
						</Grid>
					</Grid>
					<Grid container  >
						<Grid item xs={12}>
							<Typography className="heading" variant="h5" style={{ marginBottom: 10 }}>
								Brazing & Leak Testing Details
							</Typography>
						</Grid>
						<table></table>
						{brazingDetails &&
							brazingDetails.map((value, index) => (

								<React.Fragment key={index}>
									{/* Brazing & Leak Testing Details */}
									<Grid item xs={6}>
										<div>
											<h6>Table {index + 1}</h6>
											<table style={{ tableLayout: "fixed" }}>
												<tbody>
													<tr>
														<th
															style={{ textAlign: "left", paddingLeft: "10px" }}
														>
															Serial No
														</th>
														<td
															style={{ textAlign: "left", paddingLeft: "10px" }}
														>
															{value.series_ref}
														</td>
													</tr>
													<tr>
														<th
															style={{ textAlign: "left", paddingLeft: "10px" }}
														>
															Leak Date
														</th>
														<td
															style={{ textAlign: "left", paddingLeft: "10px" }}
														>
															{console.log(value.completion)}
															{value.completion}
														</td>
													</tr>
													<tr>
														<th
															style={{ textAlign: "left", paddingLeft: "10px" }}
														>
															Leak Details
														</th>
														<td
															style={{ textAlign: "left", paddingLeft: "10px" }}
														>
															{value.A !== "0" ? "A:" + value.A + " " : ""}
															{value.B !== "0" ? "B:" + value.B + " " : ""}
															{value.D !== "0" ? "D:" + value.D + " " : ""}
															{value.E !== "0" ? "E:" + value.E + " " : ""}
															{value.F !== "0" ? "F:" + value.F + " " : ""}
															{value.G !== "0" ? "G:" + value.G + " " : ""}
															{value.H !== "0" ? "H:" + value.H + " " : ""}
															{value.K !== "0" ? "K:" + value.K + " " : ""}
															{value.L !== "0" ? "L:" + value.L + " " : ""}
															{value.N !== "0" ? "N:" + value.N + " " : ""}
														</td>
													</tr>
													<tr>
														<th
															style={{ textAlign: "left", paddingLeft: "10px" }}
														>
															Workman Details
														</th>
														<td
															style={{ textAlign: "left", paddingLeft: "10px" }}
														>
															{value.uBend !== ""
																? "U-Bend:" + value.uBend + " "
																: ""}
															{value.inletOutlet !== ""
																? "Inlet-Outlet:" + value.inletOutlet + " "
																: ""}
															{value.headder !== ""
																? "Headder:" + value.headder + " "
																: ""}
															{value.headderFix !== ""
																? "Headder Fix:" + value.headderFix + " "
																: ""}
															{value.distributor !== ""
																? "Distributor:" + value.distributor + " "
																: ""}
															{value.distributorFix !== ""
																? "Distributor Fix:" +
																value.distributorFix +
																" "
																: ""}
														</td>
													</tr>
													{/* Additional details */}
												</tbody>
											</table>
										</div>
									</Grid>

									{/* Corresponding Image */}
									<Grid item xs={6}>
										{brazingTestingImages &&
											brazingTestingImages[value.series_id] && (
												<ImageList cols={1} rowHeight={200}>
													{brazingTestingImages[value.series_id]?.map(
														(item, index) => (
															<ImageListItem key={"brazing_testing" + index}>
																<img
																	src={imageURL + item}
																	srcSet={imageURL + item}
																	alt={"BrazingTesting"}
																	loading="lazy"
																	style={{
																		maxWidth: "100%",
																		maxHeight: "100%",
																		objectFit: "contain",
																	}}
																/>
																<IconButton
																	className="order-view-img"
																	onClick={() =>
																		handleClickOpenimg(imageURL + item)
																	}
																>
																	<PreviewIcon />
																</IconButton>
															</ImageListItem>
														)
													)}
												</ImageList>
											)}
									</Grid>
								</React.Fragment>
							))}
					</Grid>
				</Container>
			</>
			<Dialog
				fullScreen
				open={openImg}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleCloseImg}
				aria-describedby="alert-dialog-slide-description"
			>
				<AppBar sx={{ position: "relative" }}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleCloseImg}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							Order View Images
						</Typography>
					</Toolbar>
				</AppBar>
				<img
					src={dialogImg}
					srcSet={dialogImg}
					alt={dialogImg}
					loading="lazy"
				/>
				<DialogActions>
					<Button onClick={() => handleCloseImg("yes")}>Close</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
