import { useState, useContext, useEffect, forwardRef } from "react";
import axios from "axios";
import moment from "moment";
import { NavLink } from "react-router-dom";
import PrintIcon from "@mui/icons-material/Print";
import { ToastContainer, toast } from "react-toastify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ClockLoader } from "react-spinners";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AccessContext } from "../../constant/accessContext";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
// import utc from "dayjs/plugin/utc";
import { TickGif } from "../../commonjs/HilightRule";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import {
	Dialog,
	Button,
	Card,
	CardContent,
	Typography,
	Stack,
	DialogTitle,
	DialogActions,
	ImageList,
	ImageListItem,
	Box,
	IconButton,
	Select,
	MenuItem,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";

import OrderViewModal from "../modals/OrderViewModal";
import ModuleTools from "../modals/ModuleTools";

import {
	getLookupData,
	setOrderGeneric,
	getImagesOnly,
	getOrderAllLakVal,
	axiosInstances,
} from "../../constant/url";
import useAxiosInterceptor from "../Interceptors/axios";
import { IOSSwitch } from "../../commonjs/TableFunc";
import CommentBoxModal from "../modals/CommentBoxModal";
import { handleGenericUpdateRow } from "../../commonjs/CommonApi";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});






export default function Dispatch() {
	useAxiosInterceptor(axiosInstances)
	const access = useContext(AccessContext).authID;
	const accessModuleList = useContext(AccessContext).accessModuleList;
	const [orderList, setOrderList] = useState([]);
	const [selectedRowId, setSelectedRowId] = useState(0);
	const [selComment, setSelComment] = useState("");
	const [imageBase64, setImageBase64] = useState([]);
	const [lookUpList, setLookupList] = useState([]);
	const [openStatusCnf, setOpenStatusCnf] = useState(false);
	const [openImgDialog, setOpenImgDialog] = useState(false);
	const [animeShow, setAnimeShow] = useState(false);
	const [selEvent, setSelEvent] = useState({
		target: { name: "", checked: null },
	});
	const [openCommentDialog, setOpenCommentDialog] = useState(false);
	const [openOrderView, setOpenOrderView] = useState(false);





	const handleClickOpenStatus = (rowId, e) => {
		setSelectedRowId(rowId);
		setSelEvent(e);
		setOpenStatusCnf(true);
	};

	const handleCloseModal = (response) => {
		setOpenOrderView(false);
	};

	const handleCloseStatus = (response) => {
		if (response === "yes") {
			handleNested(selectedRowId, {
				target: { name: "dispatch_status", checked: true },
			});
		}
		setOpenStatusCnf(false);
	};

	const handleCloseImg = (response) => {
		setOpenImgDialog(false);
	};

	const onChangeInputText = (e, rowId) => {
		const { name, value } = e.target;
		const editData = orderList.map((item) =>
			item.id === rowId && name ? { ...item, [name]: value } : item
		);
		setOrderList(editData);
		handleGenericUpdate(rowId, name, value);
	};

	const [ordersWithIdss, setOrdersWithIds] = useState([]);


	const handleOrderList = () => {
		var bodyFormData = new FormData();
		bodyFormData.append("authId", access);
		bodyFormData.append("pageType", "dispatch");
		axiosInstances({
			method: "post",
			url: getOrderAllLakVal,
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then(function (response) {
				//handle success
				const res_data = response.data;
				if (res_data.status_code === 101) {
					toast("Api Authentication failed. login again.");
				} else if (res_data.status_code === 200) {
					const ret_data_cd = res_data.data_orders;


					const ordersWithIds = ret_data_cd.map(order => ({
						orderDate: order.order_date,
						orderNo: order.order_id,

					}));

					const formattedOrders = res_data.data_orders.map(order => {
						const formattedConfirmDate =
							order.order_confirm_date && order.order_confirm_date !== "undefined" && order.order_confirm_date !== "" && order.order_confirm_date !== "Invalid date"
								? dateFormateChanger(order.order_confirm_date)
								: (order.order_date ? dateFormateChanger(order.order_date) : null);

						let firstDispatchMethod =
							order.dispatch_mode.trim().charAt(0) === ","
								? order.dispatch_mode.substr(1).split(",")[0]
								: order.dispatch_mode.split(",")[0];

						const matchingDispatch = lookUpList["dispatchMode"]?.find(item => item.id === firstDispatchMethod);
						const dispatchLkpValue = matchingDispatch ? matchingDispatch.lkp_value : null;

						return {
							...order,
							order_confirm_date: formattedConfirmDate,  // Ensuring formatted date
							orderDate: order.order_date,
							orderNo: order.order_id,
							orderConfirmDate: formattedConfirmDate, // For display in UI
							paint: dispatchLkpValue
						};
					});
					setOrdersWithIds(ordersWithIds);
					setOrderList(formattedOrders);
					toast("Order Retrieved");
				} else {
					console.log(res_data.status_msg);
				}
			})
			.catch(function (response) {
				//handle error
				console.log(response);
			});
	};



	useEffect(() => {
		handleOrderList();
	}, [lookUpList]);

	const handleGenericDatetimeCheck = (rowId, e) => {
		const { name, checked } = e.target;
		const date = new Date();
		const data_val = checked ? moment(date).format("DD MMM YY H:mm") : "";
		const editData = orderList.map((item) =>
			item.id === rowId && name ? { ...item, [name]: data_val } : item
		);

		handleGenericUpdate(rowId, name, data_val);
		setOrderList(editData);
	};

	const handleNested = (rowId, e) => {
		const { name, checked } = e.target;
		var editData;
		if (name.includes("status")) {
			editData = orderList.filter((itemA) => rowId !== itemA.id);
		} else {
			editData = orderList.map((item) =>
				item.id === rowId && name ? { ...item, [name]: String(checked) } : item
			);
		}

		setOrderList(editData);
		handleGenericUpdate(rowId, name, String(checked));
	};

	const handleDispatchComments = (rowId, rowValue) => {
		handleGenericUpdate(rowId, "dispatch_comment", rowValue);
		const editData = orderList.map((item) =>
			item.id === rowId ? { ...item, ["dispatch_comment"]: rowValue } : item
		);
		setOrderList(editData);
	};

	const [loading, setLoading] = useState(false);
	const handleGenericUpdate = async (rowid, field, value) => {
		setLoading(true);

		const finalField = field == "order_confirm_date" ? "order_confirm_date" : field;
		var bodyFormData = new FormData();

		bodyFormData.append("authId", access);
		bodyFormData.append("id", rowid);
		bodyFormData.append(finalField, value);
		axios({
			method: "post",
			url: setOrderGeneric,
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then(function (response) {
				//handle success
				const res_data = response.data;
				if (res_data.status_code === 200) {
					toast(res_data.status_msg, "success");
					handleOrderList(access);
					setLoading(false);
					if (field.includes("status")) {
						setAnimeShow(true);
						const timeId = setTimeout(() => {
							// After 3 seconds set the show value to false
							setAnimeShow(false);
						}, 4000);

						return () => {
							clearTimeout(timeId);
						};
					}

					//return data
				} else {
					toast(res_data.status_msg, "error");
					return false;
				}
			})
			.catch(function (response) {
				//handle error
				console.log(response);
			});
	};



	function handleGetLookup() {
		var bodyFormData = new FormData();
		bodyFormData.append("authId", access);

		axios({
			method: "post",
			url: getLookupData,
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then(function (response) {
				//handle success
				const res_data = response.data;
				if (res_data.status_code === 200) {
					setLookupList(res_data);
				} else {
					toast(res_data.status_msg, "error");
				}
			})
			.catch(function (response) {
				//handle error
				console.log(response);
			});
	}

	function handleGetImagebyId(epid, assemblyid, brazingid) {
		var bodyFormData = new FormData();
		bodyFormData.append("authId", access);
		bodyFormData.append("epImg", epid);
		bodyFormData.append("assemblyImg", assemblyid);
		bodyFormData.append("brazingImg", brazingid);

		axios({
			method: "post",
			url: getImagesOnly,
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then(function (response) {
				//handle success
				const res_data = response.data;
				if (res_data.status_code === 200) {
					setImageBase64(res_data["data_orders"]);
				} else {
					toast(res_data.status_msg, "error");
				}
			})
			.catch(function (response) {
				//handle error
				console.log(response);
			});
	}

	const refreshData = (request) => {
		if (request) {
			handleOrderList(access);
		}
	};

	useEffect(() => {
		handleGetLookup();
		handleOrderList(access);
	}, []);


	// const dateFormateChanger = (date) => {
	// 	console.log(date);
	// 	const [day, month, year] = date.split(/\/|-/);
	// 	if (Number(day) > 12) {
	// 		return dayjs(date, "DD/MM/YYYY")
	// 	} else {
	// 		return dayjs(date)
	// 	}
	// }

	dayjs.extend(utc);
	dayjs.extend(timezone);

	const dateFormateChanger = (date) => {
		console.log("Raw Date:", date);

		const cleanDate = date.trim();

		let formattedDate;

		if (cleanDate.includes("/")) {
			// Handle "DD/MM/YYYY" format
			const parts = cleanDate.split("/");
			const day = parts[0].padStart(2, "0");
			const month = parts[1].padStart(2, "0");
			const year = parts[2];

			const normalizedDate = `${day}/${month}/${year}`;
			formattedDate = dayjs.utc(normalizedDate, "DD/MM/YYYY", true);
		} else {
			// Handle "YYYY-MM-DD" format (directly use dayjs.utc)
			formattedDate = dayjs.utc(cleanDate, "YYYY-MM-DD", true);
		}

		console.log("Formatted Date:", formattedDate.isValid() ? formattedDate.format("DD-MM-YYYY") : "Invalid Date");

		return formattedDate.isValid() ? formattedDate.format("DD-MM-YYYY") : null;
	};



	// function dateFormateChanger(dateString) {
	// 	if (!dateString) {
	// 		console.error("Empty or invalid date string:", dateString);
	// 		return null;
	// 	}

	// 	let dateParts;
	// 	if (dateString.includes('-')) {
	// 		// Handle 'yyyy-mm-dd' format
	// 		dateParts = dateString.split('-');
	// 		if (dateParts.length !== 3) {
	// 			console.error("Invalid date format:", dateString);
	// 			return null;
	// 		}
	// 		var [year, month, day] = dateParts;
	// 	} else if (dateString.includes('/')) {
	// 		// Handle 'd/m/yyyy' or 'dd/mm/yyyy' format
	// 		dateParts = dateString.split('/');
	// 		if (dateParts.length !== 3) {
	// 			console.error("Invalid date format:", dateString);
	// 			return null;
	// 		}
	// 		var [day, month, year] = dateParts;
	// 	} else {
	// 		console.error("Unsupported date format:", dateString);
	// 		return null;
	// 	}

	// 	// Convert to integers
	// 	day = parseInt(day, 10);
	// 	month = parseInt(month, 10);
	// 	year = parseInt(year, 10);

	// 	// Validate date components
	// 	if (isNaN(day) || isNaN(month) || isNaN(year)) {
	// 		console.error("Invalid date components:", dateString);
	// 		return null;
	// 	}

	// 	// Create Date object (month is zero-based in JavaScript Date)
	// 	const date = new Date(year, month - 1, day);
	// 	if (isNaN(date.getTime())) {
	// 		console.error("Invalid date object created from:", dateString);
	// 		return null;
	// 	}

	// 	// Format as 'dd-mm-yyyy'
	// 	const formattedDay = String(date.getDate()).padStart(2, '0');
	// 	const formattedMonth = String(date.getMonth() + 1).padStart(2, '0');
	// 	const formattedYear = date.getFullYear();

	// 	return `${formattedDay}-${formattedMonth}-${formattedYear}`;
	// }

	// function dateFormateChanger(dateString) {
	// 	if (!dateString) {
	// 		console.error("Empty or invalid date string:", dateString);
	// 		return null;
	// 	}

	// 	let day, month, year;

	// 	if (dateString.includes('-')) {
	// 		[year, month, day] = dateString.split('-').map(Number);
	// 	} else if (dateString.includes('/')) {
	// 		[day, month, year] = dateString.split('/').map(Number);
	// 	} else {
	// 		console.error("Unsupported date format:", dateString);
	// 		return null;
	// 	}

	// 	// Validate the extracted components
	// 	if (!day || !month || !year) {
	// 		console.error("Invalid date components:", dateString);
	// 		return null;
	// 	}

	// 	// Create and return the Date object (months are zero-indexed in JavaScript)
	// 	const date = new Date(year, month - 1, day);
	// 	if (isNaN(date.getTime())) {
	// 		console.error("Invalid date object created from:", dateString);
	// 		return null; 
	// 	}

	// 	return date;
	// }

	const columns = [
		{
			field: "order_id",
			headerName: "Order No",
			maxWidth: 70,
			renderCell: (params) => {
				return (
					<Button
						fullWidth
						onClick={() => {
							setSelectedRowId(params.row.id);
							setOpenOrderView(true);
						}}
						color="info"
						className="toolButton-grid"
					>
						{params.row.order_id}{" "}
					</Button>
				);
			},
			flex: 1,
		},
		{
			field: "full_customer_name",
			headerName: "Customer Name",
			minWidth: 90,
			flex: 1,
			cellClassName: (params) => {
				return params.value.length > 56 ? "Customer" : "Nesting";
			},
		},
		{
			field: "size",
			headerName: "Size",
			minWidth: 50,
			flex: 1,
		},
		{
			field: "sq_feet",
			headerName: "SQ Feet",
			flex: 1,
			maxWidth: 80,
			type: "number",
		},
		{
			field: "paint",
			headerName: "Mode of Dispatch",
			renderCell: (params) => {
				let firstDispatchMethod =
					params.row.dispatch_mode.trim().charAt(0) === ","
						? params.row.dispatch_mode.substr(1).split(",")[0]
						: params.row.dispatch_mode.split(",")[0];

				return (
					<Select
						fullWidth
						required={true}
						label="Dispatch Model"
						value={firstDispatchMethod}
						onChange={(event) => onChangeInputText(event, params.row.id)}
						name="dispatch_mode"
					>
						{lookUpList["dispatchMode"]?.map((item) => {
							return <MenuItem value={item.id}>{item.lkp_value}</MenuItem>;
						})}
					</Select>
				);
			},
			maxWidth: 100,
			flex: 1,
		},
		{
			field: "order_confirm_date",
			headerName: "Order Confirmation Date",
			flex: 1,
			maxWidth: 120,


			renderCell: (params) => {
				// const dateValue = (params?.row?.order_confirm_date !== "undefined" && params?.row?.order_confirm_date !== '')
				// 	? params?.row?.order_confirm_date
				// 	: params?.row?.order_date || null;

				// const formattedDate = dateValue ? dateFormateChanger(dateValue) : null;
				// console.log(formattedDate, "formattedDate");

				return (
					<DatePicker
						// value={formattedDate ? dayjs(formattedDate) : null}
						value={params.row.order_confirm_date ? dayjs(params.row.order_confirm_date, "DD-MM-YYYY") : null}
						format="DD-MM-YYYY"
						onChange={(newValue) => {
							handleGenericUpdate(
								params.row.id,
								"order_confirm_date",
								newValue ? newValue.format("YYYY-MM-DD") : null
							);
						}}

					/>

				);
			},
		},

		{
			field: "est_delivery_date",
			headerName: "CTD",
			flex: 1,
			maxWidth: 120,
			type: "date",
			editable: true,
			valueFormatter: (params) => {
				return moment(params).isValid() ? moment(params).format("DD/MM/YYYY") : ''
			}
		},
		{
			field: "dispatch_comment",
			headerName: "Dispatch Comments",
			editable: true,
			minWidth: 200,
			flex: 1,
			cellClassName: (params) => {
				return params.value.length > 76 ? "Customer" : "Nesting";
			},
		},
		{
			field: "dispatch_status",
			headerName: "Status",
			renderCell: (params) => {
				return (
					<IOSSwitch
						checked={params.row.dispatch_status === "true" ? true : false}
						sx={{ m: 1 }}
						name="dispatch_status"
						onChange={(e) => {
							handleClickOpenStatus(params.row.id, e);
						}}
					></IOSSwitch>
				);
			},
			maxWidth: 70,
			flex: 1,
		},
	];


	const [currentPage, setCurrentPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(100);

	function CustomToolbar({ rows, columns }) {

		const handlePrint = () => {

			const titleHTML = '<h2 style="text-align: center;">Dispatch</h2>';
			const startIndex = currentPage * rowsPerPage;
			const endIndex = startIndex + rowsPerPage;
			const rowsToPrint = rows.slice(startIndex, endIndex);

			console.log(`Printing rows from index ${startIndex} to ${endIndex}`, rowsToPrint); // Debugging
			const columnWidths = {

				size: 13,
				"full_customer_name": 22,
				"paint": 1,
				"order_confirm_date": 8,
				"est_delivery_date": 10,
				"sq_feet": 1,
				"dispatch_comment": 20,
				"order_id": 5
			};
			const allowedColumns = ["dispatch_status"];
			let tableHTML = '<table style="width:100%; border-collapse: collapse;">';
			tableHTML += '<thead><tr>';
			columns
				.filter(col => !allowedColumns.includes(col.field))
				.forEach((col) => {
					const width = columnWidths[col.field] || 'auto';
					tableHTML += `<th style="border: 1px solid #ddd; width: ${width}%;">${col.headerName}</th>`;
				});
			tableHTML += '</tr></thead><tbody>';

			rowsToPrint

				.forEach((row) => {
					tableHTML += '<tr>';
					columns
						.filter(col => !allowedColumns.includes(col.field))
						.forEach((col) => {
							const cellValue = row[col.field];
							tableHTML += `<td style="border: 1px solid #ddd; padding: 8px;">${cellValue}</td>`;
						});
					tableHTML += '</tr>';
				});

			tableHTML += '</tbody></table>';

			const iframe = document.createElement('iframe');
			iframe.style.position = 'absolute';
			iframe.style.width = '0';
			iframe.style.height = '0';
			iframe.style.border = 'none';
			document.body.appendChild(iframe);

			const doc = iframe.contentWindow.document;
			doc.open();
			doc.write('<html><head><title>Print DataGrid</title>');
			doc.write('<style>table { width: 100%; border-collapse: collapse; } th, td { border: 1px solid #ccc; padding: 8px; text-align: left; }</style>');
			doc.write('</head><body>');
			doc.write(titleHTML);
			doc.write(tableHTML);
			doc.write('</body></html>');
			doc.close();


			iframe.contentWindow.focus();
			iframe.contentWindow.print();

			document.body.removeChild(iframe)
		};


		return (
			<GridToolbarContainer sx={{ margintop: '5px' }}>
				<GridToolbar printOptions={{ disableToolbarButton: true }}  
					csvOptions={{ disableToolbarButton: false }}
					excelOptions={{ disableToolbarButton: true }} />
				{/* <GridToolbarExport
					printOptions={{ disableToolbarButton: true }} // Enable Print
					csvOptions={{ disableToolbarButton: false }}
					excelOptions={{ disableToolbarButton: true }}
				/> */}
				<Button onClick={handlePrint} startIcon={<PrintIcon />}
				>
					Print
				</Button>
			</GridToolbarContainer>
		);
	}

	return (

		<Box style={{ marginTop: "105px", width: "100%" }}>
			<ToastContainer />
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<TickGif show={animeShow} />
				<Card>
					<CardContent>
						<Stack direction={"row"} sx={{ flexWrap: 'wrap', gap: 3 }}>
							<Typography
								gutterBottom
								variant="h5"
								component="div"
								className="mt-2"
							>
								Dispatch
							</Typography>
							<ModuleTools
								pageName="dispatch"
								OrderData={orderList}
								refreshPage={(request) => refreshData(request)}
							/>
							<div style={{ border: "1px solid grey" }}></div>
							{accessModuleList.filter(
								(x) => x.module_name === "M4paintPacking"
							)[0].access_rw === "1" && (
									<NavLink to="/paintingpacking" className="toolButton">
										<KeyboardDoubleArrowLeftIcon style={{ color: "#BC1921" }} />
										Prev Module
									</NavLink>
								)}


						</Stack>
						<div className="mt-3" >
							<DataGrid
								// slots={{ toolbar: GridToolbar }}
								slots={{
									toolbar: () => <CustomToolbar rows={orderList} columns={columns} />
								}}
								getRowClassName={(params) => {
									if (params.indexRelativeToCurrentPage % 2 === 0) {
										return params.row.priority === "true"
											? "Mui-even secon-bg"
											: "Mui-even";
									} else {
										return params.row.priority === "true"
											? "Mui-odd secon-bg"
											: "Mui-odd";
									}
								}}
								sx={{

									"& .MuiDataGrid-columnHeader": {
										backgroundColor: "#943612",
										color: "white",
									},
									".MuiDataGrid-row.Mui-odd ": {
										backgroundColor: "#FFE1D6",
									},
									".MuiDataGrid-row.Mui-even ": {
										backgroundColor: "#F2F2F2",
									},
									".MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell":
									{

										whiteSpace: "break-spaces",
										padding: 0,
										display: "flex",
										justifyContent: "center",
										fontSize: "0.75rem",
									},
									".MuiDataGrid-columnHeaderTitleContainer": {
										display: "flex",
										justifyContent: "center",
										fontSize: "0.75rem",
									},
									"& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
										border: ".5px solid white",
									},
									"& .MuiInputBase-input": {
										fontSize: "0.74rem",
										padding: "16.5px 1px ",
									},
								}}
								processRowUpdate={(param, event) => {
									handleGenericUpdateRow(
										access,
										[
											"dispatch_comment",
											// "order_confirm_date",
											"est_delivery_date",
										],
										param
									).then((pStatus) => {
										console.log(pStatus);
									});
									return param;
								}}
								onPaginationModelChange={(model) => {
									console.log('Page:', model.page, 'PageSize:', model.pageSize);
									setCurrentPage(model.page);
									setRowsPerPage(model.pageSize);
								}}
								onProcessRowUpdateError={(param) => {
									console.log(param);
								}}
								rowHeight={50}
								columns={columns}
								rows={orderList}
								editMode="row"
							/>
						</div>
					</CardContent>
				</Card>
			</LocalizationProvider>
			<Dialog
				open={openCommentDialog}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => setOpenCommentDialog(false)}
				key={Math.random(1, 100)}
			>
				<CommentBoxModal
					content={selComment}
					retContent={(e) => {
						handleDispatchComments(selectedRowId, e);
						setOpenCommentDialog(false);
					}}
				/>
			</Dialog>
			<Dialog
				fullWidth={true}
				maxWidth={"lg"}
				open={openOrderView}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => setOpenOrderView(false)}
				key={Math.random(1, 100)}
			>
				<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
					View Order Details
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleCloseModal}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
					id="order-view-close-btn"
				>
					<CloseIcon />
				</IconButton>
				<OrderViewModal orderId={selectedRowId} key={Math.random(1, 100)} />
			</Dialog>
			<Dialog
				open={openStatusCnf}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleCloseStatus}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle>
					{"Do you want to mark the status complete of the order?"}
				</DialogTitle>
				<DialogActions>
					<Button onClick={() => handleCloseStatus("no")}>No</Button>
					<Button onClick={() => handleCloseStatus("yes")}>Yes</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={openImgDialog}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleCloseImg}
				aria-describedby="alert-dialog-slide-description"
				fullWidth
				maxWidth="lg"
				style={{ padding: "5px" }}
			>
				<Stack>
					<>
						{imageBase64.ep_photo?.length > 0 ? (
							<DialogTitle>End Plate Images</DialogTitle>
						) : (
							""
						)}
						{
							<ImageList cols={1} rowHeight={500}>
								{imageBase64.ep_photo?.map((item, index) => (
									<ImageListItem key={"epphoto" + index}>
										<img
											src={item}
											srcSet={item}
											alt={"Assembly"}
											loading="lazy"
										/>
									</ImageListItem>
								))}
							</ImageList>
						}
					</>
					<>
						{imageBase64.assembly_Photo?.length > 0 ? (
							<DialogTitle>Assembly Images</DialogTitle>
						) : (
							""
						)}

						{
							<ImageList cols={1}>
								{imageBase64.assembly_Photo?.map((item, index) => (
									<ImageListItem key={"assembly" + index}>
										<img
											src={item}
											srcSet={item}
											alt={"Assembly"}
											loading="lazy"
										/>
									</ImageListItem>
								))}
							</ImageList>
						}
					</>
					<>
						{imageBase64.brazing_Photo?.length > 0 ? (
							<DialogTitle>Brazing Images</DialogTitle>
						) : (
							""
						)}
						{
							<ImageList cols={1}>
								{imageBase64.brazing_Photo?.map((item, index) => (
									<ImageListItem key={"brazing" + index}>
										<img
											src={item}
											srcSet={item}
											alt={"Assembly"}
											loading="lazy"
										/>
									</ImageListItem>
								))}
							</ImageList>
						}
					</>
				</Stack>
				<DialogActions>
					<Button onClick={() => handleCloseImg("yes")}>Close</Button>
				</DialogActions>
			</Dialog>

		</Box>



	);
}
