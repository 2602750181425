import React, { createContext, useEffect, useState } from "react";
import "./App.css";
import AppRoutes from "./routes";
import Login from "./components/Auth/login";
import { AccessContext } from "./constant/accessContext";
import { handleVerifyLogin } from "./commonjs/LoginVerify.js";
import { useNavigate } from "react-router-dom";
// import { UserContext } from "./useContext";
// import M1cncNesting from "./m1_cncNesting";
import "react-toastify/dist/ReactToastify.css";
const App = () => {
    const navigate = useNavigate();
    const [authID, setAuthID] = useState(null);
    const [accessModuleList, setAccessModuleList] = useState(null);
    const [ordersWithIdss, setOrdersWithIdss] = useState([]);
    const handleLogin = (access_code) => {
        if (access_code !== "") {
            setAuthID(access_code.access_code);
            setAccessModuleList(access_code.accessModuleList);
            if (access_code.access_code) {
                navigate("/");
            }
        } else {
            alert("Invalid username or password");
        }
    };
    
 
    const handleLogout = () => {
		localStorage.removeItem("authId");
		localStorage.removeItem("accessModuleList");
		navigate("/login")
    };
     
    return (
        <div>
            {localStorage.getItem("authId") ? (
                <AccessContext.Provider
                    value={{
                        authID: localStorage.getItem("authId"),
                        handleLogout: handleLogout,
                        accessModuleList: JSON.parse(localStorage.getItem("accessModuleList")),
                    }} >
                        
                    <AppRoutes onLogout={handleLogout} />
                </AccessContext.Provider>
            ) : (
                <Login onLogin={(retAccessCode) => handleLogin(retAccessCode)} />
            )}
        </div>
    );
};
export default App;



// import React from "react";
// import { useEffect } from "react";
// import "./App.css";
// import AppRoutes from "./routes";
// import Login from "./components/Auth/login";
// import { useDispatch, useSelector } from "react-redux";
// import { logout } from "./components/Auth/actions/loginaction";
// import { useNavigate } from "react-router-dom";
// import "react-toastify/dist/ReactToastify.css";

// const App = () => {
//     // const navigate = useNavigate();
//     // const dispatch = useDispatch();
//     const authID = useSelector((state) => state.auth?.accessCode);
//     console.log(authID)

   

//     // const success = useSelector((state) => state.auth?.success); // Assuming success comes from Redux

//     // useEffect(() => {
//     //     if (success) {
//     //         console.log("Navigating to /main...");
//     //         navigate("/main");
//     //     }
//     // }, [success, navigate]);

//     return <div>{authID ? <AppRoutes /> : <Login />}</div>;
// };

// export default App;

