import { useState, useContext, useEffect, forwardRef } from "react";
import axios from "axios";
import moment from "moment";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import PrintIcon from "@mui/icons-material/Print";
import { AccessContext } from "../../constant/accessContext";

import { TickGif } from "../../commonjs/HilightRule";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import { DataGrid, GridToolbar, GridToolbarContainer,GridToolbarExport  } from "@mui/x-data-grid";
import {
	Dialog,
	Button,
	Card,
	CardContent,
	Typography,
	Stack,
	Checkbox,
	DialogTitle,
	DialogActions,
	ImageList,
	ImageListItem,
	Box,
	IconButton,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";

import OrderViewModal from "../modals/OrderViewModal";
import ModuleTools from "../modals/ModuleTools";
import { handleFindLookup_arr } from "../../commonjs/CommonFun";

import {
	getLookupData,
	setOrderGeneric,
	getImagesOnly,
	getOrderAllLakVal,
	axiosInstances,
} from "../../constant/url";
import useAxiosInterceptor from "../Interceptors/axios";
import { IOSSwitch } from "../../commonjs/TableFunc";
import CommentBoxModal from "../modals/CommentBoxModal";
import { handleGenericUpdateRow } from "../../commonjs/CommonApi";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});
export default function M4paintPacking() {
	useAxiosInterceptor(axiosInstances)
	const access = useContext(AccessContext).authID;
	const accessModuleList = useContext(AccessContext).accessModuleList;
	const [orderList, setOrderList] = useState([]);
	const [selectedRowId, setSelectedRowId] = useState(0);
	const [selComment, setSelComment] = useState("");
	const [imageBase64, setImageBase64] = useState([]);
	const [lookUpList, setLookupList] = useState([]);
	const [openStatusCnf, setOpenStatusCnf] = useState(false);
	const [openImgDialog, setOpenImgDialog] = useState(false);
	const [animeShow, setAnimeShow] = useState(false);
	const [selEvent, setSelEvent] = useState({
		target: { name: "", checked: null },
	});
	const [openCommentDialog, setOpenCommentDialog] = useState(false);
	const [openOrderView, setOpenOrderView] = useState(false);

	const _handleGenericUpdateRow = (access, fields, rowData) => {
		handleGenericUpdateRow(access, fields, rowData).then(function (d) {
			const newId = orderList.findIndex(function (item) {
				return d.id === item.id;
			});
			var newOrderList = Object.assign([...orderList], { [newId]: d });
			setOrderList(newOrderList);
		});
	};
	const handleClickOpenStatus = (rowId, e) => {
		setSelectedRowId(rowId);
		setSelEvent(e);
		setOpenStatusCnf(true);
	};

	const handleCloseStatus = (response) => {
		if (response === "yes") {
			handleNested(selectedRowId, {
				target: { name: "pp_status", checked: true },
			});
		}
		setOpenStatusCnf(false);
	};

	const handleCloseImg = (response) => {
		setOpenImgDialog(false);
	};
	
	const handleCloseModal = (response) => {
		setOpenOrderView(false);
	};

	const handleOrderList = () => {
		var bodyFormData = new FormData();
		bodyFormData.append("authId", access);
		bodyFormData.append("pageType", "paintingPacking");
		axiosInstances({
			method: "post",
			url: getOrderAllLakVal,
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then(function (response) {
				//handle success
				const res_data = response.data;
				if (res_data.status_code === 101) {
					toast("Api Authentication failed. login again.");
				} else if (res_data.status_code === 200) {
					const ret_data_cd = res_data.data_orders;
					console.log(ret_data_cd)

					if (Array.isArray(ret_data_cd) && ret_data_cd.length > 0) {
						const updatedData = ret_data_cd.map(item => {
							const paintt = handleFindLookup_arr(
								lookUpList,
								"paintType",
								item.paint
							);
					
							const packing = handleFindLookup_arr(
								lookUpList,
								"packingType",
								item?.packing_type
							);
					
							return {
								...item,
								paint: paintt,
								packing_type: packing
							};
						});
					
						setOrderList(updatedData);  
						toast("Order Retrieved");
					} 
				} else {
					console.log(res_data.status_msg);
				}
			})
			.catch(function (response) {
				//handle error
				console.log(response);
			});
	};


	useEffect(() => {
		handleOrderList();
	}, [lookUpList]);

	const handleGenericDatetimeCheck = (rowId, e) => {
		const { name, checked } = e.target;
		const date = new Date();
		const data_val = checked ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "";
		const editData = orderList.map((item) =>
			item.id === rowId && name ? { ...item, [name]: data_val } : item
		);

		handleGenericUpdate(rowId, name, data_val);
		setOrderList(editData);
	};

	const handleNested = (rowId, e) => {
		const { name, checked } = e.target;
		var idx = orderList.findIndex((item) => item.id === rowId);
		if (name === "pp_status") {
			if (
				!moment(orderList.at(idx).pp_datetime, "YYYY-MM-DD HH:mm:ss").isValid()
			) {
				toast("Please check Painting before updating status.", "warning");
				return;
			}
		}

		var editData;
		if (name.includes("status")) {
			editData = orderList.filter((itemA) => rowId !== itemA.id);
		} else {
			editData = orderList.map((item) =>
				item.id === rowId && name ? { ...item, [name]: String(checked) } : item
			);
		}

		setOrderList(editData);
		handleGenericUpdate(rowId, name, String(checked));
	};

	const handleDispatchComments = (rowId, rowValue) => {
		handleGenericUpdate(rowId, "dispatch_comment", rowValue);
		const editData = orderList.map((item) =>
			item.id === rowId ? { ...item, ["dispatch_comment"]: rowValue } : item
		);
		setOrderList(editData);
	};

	const handleGenericUpdate = async (rowid, field, value) => {
		var bodyFormData = new FormData();
		bodyFormData.append("authId", access);
		bodyFormData.append("id", rowid);
		bodyFormData.append(field, value);
		axios({
			method: "post",
			url: setOrderGeneric,
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then(function (response) {
				//handle success
				const res_data = response.data;
				if (res_data.status_code === 200) {
					toast(res_data.status_msg, "success");
					handleOrderList(access);
					if (field.includes("status")) {
						setAnimeShow(true);
						const timeId = setTimeout(() => {
							// After 3 seconds set the show value to false
							setAnimeShow(false);
						}, 4000);

						return () => {
							clearTimeout(timeId);
						};
					}

					//return data
				} else {
					toast(res_data.status_msg, "error");
					return false;
				}
			})
			.catch(function (response) {
				//handle error
				console.log(response);
			});
	};

	function handleGetLookup() {
		var bodyFormData = new FormData();
		bodyFormData.append("authId", access);

		axios({
			method: "post",
			url: getLookupData,
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then(function (response) {
				//handle success
				const res_data = response.data;
				if (res_data.status_code === 200) {
					setLookupList(res_data);
				} else {
					toast(res_data.status_msg, "error");
				}
			})
			.catch(function (response) {
				//handle error
				console.log(response);
			});
	}

	function handleGetImagebyId(epid, assemblyid, brazingid) {
		var bodyFormData = new FormData();
		bodyFormData.append("authId", access);
		bodyFormData.append("epImg", epid);
		bodyFormData.append("assemblyImg", assemblyid);
		bodyFormData.append("brazingImg", brazingid);

		axios({
			method: "post",
			url: getImagesOnly,
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then(function (response) {
				//handle success
				const res_data = response.data;
				if (res_data.status_code === 200) {
					setImageBase64(res_data["data_orders"]);
				} else {
					toast(res_data.status_msg, "error");
				}
			})
			.catch(function (response) {
				//handle error
				console.log(response);
			});
	}

	const refreshData = (request) => {
		if (request) {
			handleOrderList(access);
		}
	};

	useEffect(() => {
		handleGetLookup();
		handleOrderList(access);
	}, []);

	const columns = [
		{
			field: "order_id",
			headerName: "Order No",
			maxWidth: 70,
			renderCell: (params) => {
				return (
					<Button
						fullWidth
						onClick={() => {
							setSelectedRowId(params.row.id);
							setOpenOrderView(true);
						}}
						color="info"
						className="toolButton-grid "
					>
						{params.row.order_id}{" "}
					</Button>
				);
			},
			flex: 1,
		},
		{
			field: "full_customer_name",
			headerName: "Customer Name",
			minWidth: 90,
			flex: 1,
			cellClassName: (params) => {
				return params.value.length >56 ? "Customer" : "Nesting";
			},
		},
		{
			field: "size",
			headerName: "Size",
			minWidth: 120,
			flex: 1,
		},
		{
			field: "sq_feet",
			headerName: "SQ Feet",
			flex: 1,
			maxWidth: 80,
			type: "number",
		},
		{
			field: "paint",
			headerName: "Paint Type",
			// renderCell: (params) => {
			// 	return handleFindLookup_arr(lookUpList, "paintType", params.value);
			// },
			maxWidth: 150,
			flex: 1,
		},
		{
			field: "packing_type",
			headerName: "Packing Type",
			// renderCell: (params) => {
			// 	return handleFindLookup_arr(
			// 		lookUpList,
			// 		"packingType",
			// 		params?.row?.packing_type
			// 	);
			// },
			maxWidth: 150,
			flex: 1,
		},
		{
			field: "dispatch_comment",
			headerName: "Dispatch Comments",
			editable: true,
			minWidth: 200,
			flex: 1,
			cellClassName: (params) => {
				return params.value.length >76 ? "Customer" : "Nesting";
			},
		},
		{
			field: "pp_datetime",
			headerName: "Painting",
			renderCell: (params) => {
				return (
					<Checkbox
						sx={{ m: 1 }}
						name="pp_datetime"
						onChange={(e) => handleGenericDatetimeCheck(params.row.id, e)}
						checked={moment(
							params.row.pp_datetime,
							"YYYY-MM-DD HH:mm:ss"
						).isValid()}
					/>
				);
			},
			maxWidth: 70,
			flex: 1,
		},
		{
			field: "pp_status",
			headerName: "Status",
			renderCell: (params) => {
				return (
					<IOSSwitch
						checked={params.row.pp_status === "true" ? true : false}
						sx={{ m: 1 }}
						name="pp_status"
						onChange={(e) => {
							handleClickOpenStatus(params.row.id, e);
						}}
					></IOSSwitch>
				);
			},
			maxWidth: 70,
			flex: 1,
		},
	];


	const [currentPage, setCurrentPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(100);

	function CustomToolbar({ rows, columns }) {

		const handlePrint = () => {

			const titleHTML = '<h2 style="text-align: center;">Painting and Packing</h2>';
			const startIndex = currentPage * rowsPerPage;
			const endIndex = startIndex + rowsPerPage;
			const rowsToPrint = rows.slice(startIndex, endIndex);

			console.log(`Printing rows from index ${startIndex} to ${endIndex}`, rowsToPrint); // Debugging
			const columnWidths = {
				"order_id":5,
				"full_customer_name":18,
				"size":15,
				"sq_feet":5,
				"paint":10,
				"packing_type":8
			};
			const allowedColumns = ["pp_status","pp_datetime"];
			let tableHTML = '<table style="width:100%; border-collapse: collapse;">';
			tableHTML += '<thead><tr>';
			columns
			.filter(col => !allowedColumns.includes(col.field))
			.forEach((col) => {
				const width = columnWidths[col.field] || 'auto';
				tableHTML += `<th style="border: 1px solid #ddd;width: ${width}%;">${col.headerName}</th>`;
			});
			tableHTML += '</tr></thead><tbody>';

			rowsToPrint.forEach((row) => {
				tableHTML += '<tr>';
				columns
				.filter(col => !allowedColumns.includes(col.field))
				.forEach((col) => {
					const cellValue = row[col.field];
					tableHTML += `<td style="border: 1px solid #ddd; padding: 8px;">${cellValue}</td>`;
				});
				tableHTML += '</tr>';
			});

			tableHTML += '</tbody></table>';

			const iframe = document.createElement('iframe');
			iframe.style.position = 'absolute';
			iframe.style.width = '0';
			iframe.style.height = '0';
			iframe.style.border = 'none';
			document.body.appendChild(iframe);

			const doc = iframe.contentWindow.document;
			doc.open();
			doc.write('<html><head><title>Print DataGrid</title>');
			doc.write('<style>table { width: 100%; border-collapse: collapse; } th, td { border: 1px solid #ccc; padding: 8px; text-align: left; }</style>');
			doc.write('</head><body>');
			doc.write(titleHTML);
			doc.write(tableHTML);
			doc.write('</body></html>');
			doc.close();


			iframe.contentWindow.focus();
			iframe.contentWindow.print();

			document.body.removeChild(iframe)
		};


		return (
			<GridToolbarContainer sx={{ margintop: '5px' }}>
				<GridToolbar printOptions={{ disableToolbarButton: true }} // Enable Print
					csvOptions={{ disableToolbarButton: false }}
					excelOptions={{ disableToolbarButton: true }}/>
				{/* <GridToolbarExport
					printOptions={{ disableToolbarButton: true }} // Enable Print
					csvOptions={{ disableToolbarButton: false }}
					excelOptions={{ disableToolbarButton: true }}
				/> */}
				<Button onClick={handlePrint} startIcon={<PrintIcon />}
				>
					Print
				</Button>
			</GridToolbarContainer>
		);
	}

	return (
		<Box style={{ marginTop: "105px", width: "100%" }}>
			<ToastContainer />
			<TickGif show={animeShow} />
			<Card>
				<CardContent>
					<Stack direction={"row"} sx={{ flexWrap: 'wrap', gap: 2}}>
						<Typography
							gutterBottom
							variant="h5"
							component="div"
							className="mt-2"
						>
							Painting and Packing
						</Typography>
						<ModuleTools
							pageName="paintingPacking"
							OrderData={orderList}
							refreshPage={(request) => refreshData(request)}
						/>
						<div style={{ border: "1px solid grey" }}></div>
						{accessModuleList.filter(
							(x) => x.module_name === "M4brazingLeak"
						)[0].access_rw === "1" && (
							<NavLink to="/brazing" className="toolButton">
								<KeyboardDoubleArrowLeftIcon style={{ color: "#BC1921" }} />
								Prev Module
							</NavLink>
						)}
						{accessModuleList.filter((x) => x.module_name === "Dispatch")[0]
							.access_rw === "1" && (
							<NavLink to="/dispatch" className="toolButton">
								Next Module
								<KeyboardDoubleArrowRightIcon style={{ color: "#BC1921" }} />
							</NavLink>
						)}
					</Stack>
					<div className="mt-3"  >
						<DataGrid
							// slots={{ toolbar: GridToolbar }}
							slots={{
								toolbar: () => <CustomToolbar rows={orderList} columns={columns} />
							}}
							getRowClassName={(params) => {
								if (params.indexRelativeToCurrentPage % 2 === 0) {
									return params.row.priority === "true"
										? "Mui-even secon-bg"
										: "Mui-even";
								} else {
									return params.row.priority === "true"
										? "Mui-odd secon-bg"
										: "Mui-odd";
								}
							}}
							sx={{
								"& .MuiDataGrid-columnHeader": {
									backgroundColor: "#943612",
									color: "white",
								},
								".MuiDataGrid-row.Mui-odd ": {
									backgroundColor: "#FFE1D6",
								},
								".MuiDataGrid-row.Mui-even ": {
									backgroundColor: "#F2F2F2",
								},
								".MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell":
									{
										 
										whiteSpace: "break-spaces",
										padding: 0,
										display: "flex",
										justifyContent: "center",
										fontSize: "0.75rem",
									},
								".MuiDataGrid-columnHeaderTitleContainer": {
									display: "flex",
									justifyContent: "center",
									fontSize: "0.75rem",
								},
								"& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
									border: ".5px solid white",
								},
								"& .MuiInputBase-input": {
									fontSize: "0.74rem",
									padding: "16.5px 1px ",
								},
							}}
							processRowUpdate={(param, event) => {
								_handleGenericUpdateRow(
									access,
									["dispatch_comment"],
									param
								).then((pStatus) => {
									console.log(pStatus);
								});
								return param;
							}}
							onPaginationModelChange={(model) => {
								console.log('Page:', model.page, 'PageSize:', model.pageSize);
								setCurrentPage(model.page);
								setRowsPerPage(model.pageSize);
							}}
							onProcessRowUpdateError={(param) => {
								console.log(param);
							}}
							rowHeight={50}
							columns={columns}
							rows={orderList}
							editMode="row"

						/>
					</div>
				</CardContent>
			</Card>

			<Dialog
				open={openCommentDialog}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => setOpenCommentDialog(false)}
				key={Math.random(1, 100)}
			>
				<CommentBoxModal
					content={selComment}
					retContent={(e) => {
						handleDispatchComments(selectedRowId, e);
						setOpenCommentDialog(false);
					}}
				/>
			</Dialog>

			<Dialog
				fullWidth={true}
				maxWidth={"lg"}
				open={openOrderView}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => setOpenOrderView(false)}
				key={Math.random(1, 100)}
			>
				<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
					View Order Details
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleCloseModal}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
					id="order-view-close-btn"
				>
					<CloseIcon />
				</IconButton>
				<OrderViewModal orderId={selectedRowId} key={Math.random(1, 100)} />
			</Dialog>

			<Dialog
				open={openStatusCnf}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleCloseStatus}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle>
					{"Do you want to mark the status complete of the order?"}
				</DialogTitle>
				<DialogActions>
					<Button onClick={() => handleCloseStatus("no")}>No</Button>
					<Button onClick={() => handleCloseStatus("yes")}>Yes</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={openImgDialog}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleCloseImg}
				aria-describedby="alert-dialog-slide-description"
				fullWidth
				maxWidth="lg"
				style={{ padding: "5px" }}
			>
				<Stack>
					<>
						{imageBase64.ep_photo?.length > 0 ? (
							<DialogTitle>End Plate Images</DialogTitle>
						) : (
							""
						)}
						{
							<ImageList cols={1} rowHeight={500}>
								{imageBase64.ep_photo?.map((item, index) => (
									<ImageListItem key={"epphoto" + index}>
										<img
											src={item}
											srcSet={item}
											alt={"Assembly"}
											loading="lazy"
										/>
									</ImageListItem>
								))}
							</ImageList>
						}
					</>
					<>
						{imageBase64.assembly_Photo?.length > 0 ? (
							<DialogTitle>Assembly Images</DialogTitle>
						) : (
							""
						)}

						{
							<ImageList cols={1}>
								{imageBase64.assembly_Photo?.map((item, index) => (
									<ImageListItem key={"assembly" + index}>
										<img
											src={item}
											srcSet={item}
											alt={"Assembly"}
											loading="lazy"
										/>
									</ImageListItem>
								))}
							</ImageList>
						}
					</>
					<>
						{imageBase64.brazing_Photo?.length > 0 ? (
							<DialogTitle>Brazing Images</DialogTitle>
						) : (
							""
						)}
						{
							<ImageList cols={1}>
								{imageBase64.brazing_Photo?.map((item, index) => (
									<ImageListItem key={"brazing" + index}>
										<img
											src={item}
											srcSet={item}
											alt={"Assembly"}
											loading="lazy"
										/>
									</ImageListItem>
								))}
							</ImageList>
						}
					</>
				</Stack>
				<DialogActions>
					<Button onClick={() => handleCloseImg("yes")}>Close</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}
