import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { useNavigate } from 'react-router-dom';
import { axiosInstances } from "../../constant/url";

const useAxiosInterceptor = () => {

    const navigate = useNavigate();
    
    const handleAxiosResponse = (response) => {
      console.log(response)
      console.log(response.data.status_code );
        try {
           let res_data;

          if (typeof response.data === "string") {
             const jsonObjects = response.data
               .split("}{")
               .map((str, index, array) => {
                 if (index === 0) {
                   return `${str}}`;
                 } else if (index === array.length - 1) {
                   return `{${str}`;
                 } else {
                   return `{${str}}`;
                 }
               });
             res_data = jsonObjects.map((jsonStr) => JSON.parse(jsonStr));
           } else {
             res_data = [response.data];
           }
           if (response.data.status_code == 401 || response.data.status_code == 101) {
            console.log("yes")
             localStorage.removeItem("authId");
             localStorage.removeItem("accessModuleList");
             navigate("/login");
           }
         } catch (error) {
           console.log("e", error);
         }
         return response;
       };

       axiosInstances.interceptors.response.use(handleAxiosResponse, (error) => {
       return Promise.reject(error);
    });
};
  export default useAxiosInterceptor;
  
  
  
  


// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { axiosInstances } from "../../constant/url"; // Ensure axiosInstances is properly exported

// const useAxiosInterceptor = () => {
//     const navigate = useNavigate();

//     useEffect(() => {
//         const handleAxiosResponse = (response) => {
//             try {
//                 let res_data = response.data;

        
//                 if (typeof res_data === "string") {
//                     const jsonObjects = res_data.split("}{").map((str, index, array) => {
//                         if (index === 0) return `${str}}`;
//                         if (index === array.length - 1) return `{${str}`;
//                         return `{${str}}`;
//                     });
//                     res_data = jsonObjects.map((jsonStr) => JSON.parse(jsonStr));
//                 } else {
//                     res_data = [res_data];  
//                 }

                
//                 if (res_data[0]?.status_code === 401 || res_data[0]?.status_code === 101) {
//                     localStorage.removeItem("authId");
//                     localStorage.removeItem("accessModuleList");
//                     navigate("/login");
//                 }
//             } catch (error) {
//                 console.error("Error processing response:", error);
//             }
//             return response;
//         };

//         const interceptor = axiosInstances.interceptors.response.use(
//             handleAxiosResponse,
//             (error) => Promise.reject(error)  
//         );

//         return () => {
//             axiosInstances.interceptors.response.eject(interceptor); 
//         };
//     }, [navigate]);  

//     return axiosInstances;
// };

// export default useAxiosInterceptor;

  
  
  
  
  
  