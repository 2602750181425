import React, { useState, useContext, forwardRef } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomerModal from "../modals/CustomerModal";
import { AccessContext } from "../../constant/accessContext";
import { getCustomersDataAll, delCustomersData } from "../../constant/url";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, TextField,InputAdornment } from "@mui/material";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import Slide from "@mui/material/Slide";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PrintIcon from "@mui/icons-material/Print";
const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomerTable() {
	const access = useContext(AccessContext).authID;
	const [selectedRowId, setSelectedRowId] = React.useState([]);
	const [customerNameList, setCustomerNameList] = useState([]);
	const [isUpdated, setIsUpdate] = useState(false);
	const [openStatusCnf, setOpenStatusCnf] = useState(false);

	const handleCloseStatus = (response) => {
		if (response === "yes") {
			handleCustomerDelete(selectedRowId);
		}
		setOpenStatusCnf(false);
	};

	const handleCustomerDelete = (rowId) => {
		var bodyFormData = new FormData();
		bodyFormData.append("authId", access);
		bodyFormData.append("id", rowId);

		axios({
			method: "post",
			url: delCustomersData,
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then(function (response) {
				//handle success
				const res_data = response.data;
				if (res_data.status_code === 101) {
					toast("Api Aithentication failed. login again.");
				} else if (res_data.status_code === 200) {
					toast(res_data.status_msg, "success");
					setIsUpdate(true);
				} else {
					console.log(res_data.status_msg);
				}
			})
			.catch(function (response) {
				//handle error
				console.log(response);
			});
	};

	const handleCustomerList = (authID) => {
		var bodyFormData = new FormData();
		bodyFormData.append("authId", authID);

		axios({
			method: "post",
			url: getCustomersDataAll,
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then(function (response) {
				//handle success
				const res_data = response.data;
				if (res_data.status_code === 101) {
					toast("Api Aithentication failed. login again.");
				} else if (res_data.status_code === 200) {
					const ret_data_cd = res_data.data_cust;
					setCustomerNameList(ret_data_cd);
				} else {
					console.log(res_data.status_msg);
				}
			})
			.catch(function (response) {
				//handle error
				console.log(response);
			});
	};

	const columns = [
		{
			field: "fname",
			headerName: "Full Name",
			flex: 1,

			cellClassName: (params) => {
				return params.value.length > 76 ? "Customer" : "Nesting";
			},
		},
		{
			field: "email",
			headerName: "Email",
			flex: 1,

			cellClassName: (params) => {
				return params.value.length > 76 ? "Customer" : "Nesting";
			},
		},
		{
			field: "phone",
			headerName: "Phone",
			flex: 1,

		},
		{
			field: "poc",
			headerName: "Point Of Contact",
			flex: 1,

		},
		{
			field: "address",
			headerName: "Address",
			flex: 1,
			cellClassName: "Customer",
		},
		{
			field: "id",
			headerName: "Action",
			renderCell: (params) => {
				return (
					<div>
						<Tooltip title="Edit" color="info">
							<IconButton
								data-bs-toggle="modal"
								data-bs-target="#staticBackdrop"
								onClick={() => setSelectedRowId(params.row.id)}
							>
								<CreateRoundedIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Delete">
							<IconButton
								onClick={() => {
									setOpenStatusCnf(true);
									setSelectedRowId(params.row.id);
								}}
								color="error"
							>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					</div>
				);
			},


			flex: 1,
		},
	];

	const [open, setOpen] = useState(false);
	const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
	const correctPassword = "YourPassword123";

	const handleOpenDialog = () => {
		setOpen(true);
	};

	const handleCloseDialog = () => {
		setOpen(false);
		setPassword("");
	};

	const handlePasswordSubmit = () => {
		if (password === correctPassword) {
			exportToCsv();
			handleCloseDialog();
		} else {
			toast.error("Incorrect password. Export canceled.");
		}
	};


	const exportToCsv = () => {
		const allColumns = columns;
		const visibleRows = customerNameList;

		const excludedFields = ["id"];
		const csvData = [
			// Add the header row
			allColumns
				.filter((col) => !excludedFields.includes(col.field))
				.map((col) => col.headerName)
				.join(","),
			// Add the data rows, excluding empty rows
			...visibleRows
				.filter((row) =>
					allColumns.some((col) => !excludedFields.includes(col.field) && row[col.field]) // Check if any column in the row has a value
				)
				.map((row) =>
					allColumns
						.filter((col) => !excludedFields.includes(col.field))
						.map((col) => {
							let value = row[col.field];

							if (col.field === "address") {
								// Replace newlines and carriage returns with a space
								value = value.replace(/[\r\n]/g, " ");
								// Remove special characters
								value = value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}\[\]\\\/]/gi, '');
							}

							if (typeof value === "object" && value !== null) {
								return `"Program: ${value.program || ""}"`;
							}

							// Ensure all values are properly quoted to handle commas and special characters
							return `"${value || ""}"`;
						})
						.join(",")
				),
		];

		const csvString = csvData.join("\n");
		const blob = new Blob([csvString], { type: "text/csv" });
		const url = URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = "export.csv";
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	function EditToolbar({ rows, columns }) {

		const handlePrint = () => {

			const startIndex = currentPage * rowsPerPage; // Calculate the starting index
			const endIndex = startIndex + rowsPerPage;   // Calculate the ending index
			const rowsToPrint = rows.slice(startIndex, endIndex); // Slice rows for the current page

			console.log(`Printing rows from index ${startIndex} to ${endIndex}`, rowsToPrint); // Debugging

			let tableHTML = '<table style="width:100%; border-collapse: collapse;">';
			tableHTML += '<thead><tr>';
			columns.forEach((col) => {
				tableHTML += `<th style="border: 1px solid #ddd; padding: 8px;">${col.headerName}</th>`;
			});
			tableHTML += '</tr></thead><tbody>';

			rowsToPrint.forEach((row) => {
				tableHTML += '<tr>';
				columns.forEach((col) => {
					const cellValue = row[col.field] !== undefined && row[col.field] !== null ? row[col.field] : '';
					tableHTML += `<td style="border: 1px solid #ddd; padding: 8px;">${cellValue}</td>`;
				});
				tableHTML += '</tr>';
			});

			tableHTML += '</tbody></table>';

			// Create a hidden iframe for printing
			const iframe = document.createElement('iframe');
			iframe.style.position = 'absolute';
			iframe.style.width = '0';
			iframe.style.height = '0';
			iframe.style.border = 'none';
			document.body.appendChild(iframe);

			const doc = iframe.contentWindow.document;
			doc.open();
			doc.write('<html><head><title>Print DataGrid</title>');
			doc.write('<style>table { width: 100%; border-collapse: collapse; } th, td { border: 1px solid #ccc; padding: 8px; text-align: left; }</style>');
			doc.write('</head><body>');
			doc.write(tableHTML);
			doc.write('</body></html>');
			doc.close();

			// Wait for the content to load and trigger the print
			iframe.contentWindow.focus();
			iframe.contentWindow.print();

			// Remove the iframe after printing
			document.body.removeChild(iframe)
		};

		return (
			<Box sx={{ display: 'flex' }}>
				<GridToolbarContainer>
				<GridToolbar printOptions={{ disableToolbarButton: true }}  
					csvOptions={{ disableToolbarButton: true }}
					excelOptions={{ disableToolbarButton: true }}/>
					<Button onClick={handleOpenDialog}>
						Export to CSV
					</Button>
					<Button onClick={handlePrint} startIcon={<PrintIcon />}> 
						Print
					</Button>
				</GridToolbarContainer>
			</Box>
		);
	}


	// function EditToolbar({ rows, columns }) {
	// 	return (
	// 		<GridToolbarContainer>

	// 			<Button onClick={handleOpenDialog}>
	// 				Export to CSV
	// 			</Button>
	// 			<Button onClick={handlePrint}>
	// 				Print
	// 			</Button>





	// 		</GridToolbarContainer>
	// 	);


	// }

	const [currentPage, setCurrentPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(100);


	React.useEffect(() => {
		handleCustomerList(access);
	}, []);

	React.useEffect(() => {
		if (isUpdated) {
			handleCustomerList(access);
			setIsUpdate(false);
		}
	}, [isUpdated]);

	return (
		<div style={{ marginTop: "110px", width: "100%" }}>
			<main className="main pt-0">
				<div className="content">
					<ToastContainer />
					<div className="row">
						<div className="col-xl-12">
							<div className="card shadow-card glass-card">
								<div className="card-body">
									<Box>
										<DataGrid
											slots={{
												toolbar: () => <EditToolbar rows={customerNameList} columns={columns} />
											}}
											getRowClassName={(params) => {
												if (params.indexRelativeToCurrentPage % 2 === 0) {
													return "Mui-even";
												} else {
													return "Mui-odd";
												}
											}}
											sx={{
												"& .MuiDataGrid-columnHeader": {
													backgroundColor: "#943612",
													color: "white",
												},
												".MuiDataGrid-row.Mui-odd ": {
													backgroundColor: "#FFE1D6",
												},
												".MuiDataGrid-row.Mui-even ": {
													backgroundColor: "#F2F2F2",
												},
												".MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell":
												{
													overflow: "visible !important",
													whiteSpace: "break-spaces",
													padding: 0,
													display: "flex",
													justifyContent: "center",
													fontSize: "0.75rem",
												},
												".MuiDataGrid-columnHeaderTitleContainer": {
													display: "flex",
													justifyContent: "center",
													fontSize: "0.75rem",
												},
												"& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
													border: ".5px solid white",
												},
												"& .MuiInputBase-input": {
													fontSize: "0.74rem",
													padding: "16.5px 1px ",
												},
											}}
											rowHeight={50}
											columns={columns}
											rows={customerNameList}
											editMode="cell"
											onPaginationModelChange={(model) => {
												console.log('Page:', model.page, 'PageSize:', model.pageSize);
												setCurrentPage(model.page);
												setRowsPerPage(model.pageSize);
											}}

										/>
									</Box>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
			<CustomerModal
				custId={selectedRowId}
				isUpdate={(status) => setIsUpdate(status)}
			/>
			<Dialog
				open={openStatusCnf}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleCloseStatus}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle>{"Do you want to delete the customer ?"}</DialogTitle>
				<DialogActions>
					<Button onClick={() => handleCloseStatus("no")}>No</Button>
					<Button onClick={() => handleCloseStatus("yes")}>Yes</Button>
				</DialogActions>
				<Dialog open={open} onClose={handleCloseDialog}>
					<DialogTitle>Enter Password</DialogTitle>
					<DialogContent>
						<TextField
						    type={showPassword ? "text" : "password"}
							variant="outlined"
							fullWidth
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							autoFocus
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseDialog} color="secondary">
							Cancel
						</Button>
						<Button onClick={handlePasswordSubmit} color="primary" variant="contained">
							Submit
						</Button>
					</DialogActions>
				</Dialog>
			</Dialog>
		</div>
	);
}
